import React, { useState } from "react";

import Banner from "../../components/header/Banner";
import { GoArrowRight } from "react-icons/go";
import { LuEye } from "react-icons/lu";
import { NavLink, useNavigate } from "react-router-dom";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
// import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FaRegEyeSlash } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import { GoogleLogin } from "@react-oauth/google";
import "./login.css";
import PhoneInput from "react-phone-input-2";

import { Formik } from "formik";
import * as Yup from "yup";
import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import Default from "../../components/default/Default";
import "./login.css";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  password: Yup.string().required("Password is required"),
  tnc: Yup.string().required("Condition is required"),
});

// Initial Values
const adsInformationInitialValue = {
  name: "",
  email: "",
  phoneNumber: "",
  password: "",
  tnc: false,
};

const Register = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleOnSubmitRegisterForm = async (values) => {
    try {
      const options = {
        showLoader: "register-button",
      };
      const response = await PostRequest("/register", values, options);
      navigate("/verification", { state: response.token });
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelRegisterGoogle = async (credentialResponse) => {
    try {
      const data = {
        token: credentialResponse.credential,
      };

      const response = await PostRequest("/login/google", data);

      // console.log("response",response);

      if (!response.emailVerified) {
        navigate("/verification", { state: response.token });
      } else {
        window.localStorage.setItem("auth", JSON.stringify(response));
        window.localStorage.setItem("TOKEN", response.token);

        showToaster("Welcome back!");
        navigate("/home");
      }
    } catch (error) {
      console.log(error.message, "error");
      showToaster(error.message, "error");
    }
  };

  const handelShowpassword = () => {
    setShow(!show);
  };

  return (
    <Default>
      <section>
        <Banner title="Sign Up" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <div className="login-bg">
                  <div className="t-center fw-600">
                    <h3>Sign Up</h3>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="mobile-google-btn d-center">
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            handelRegisterGoogle(credentialResponse);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="btn-google btn-facebook cursor ">
                        <FaFacebookF color="#1877F2" size={17} /> &nbsp; Sign Up
                        with facebook
                      </div>
                    </div> */}
                  </div>

                  <div className="my-4 d-flex align-items-center justify-content-between">
                    <div className="login-with-email-border"></div>

                    <div className="f-16 other-login mobile-font-register">
                      or Sign Up with Email
                    </div>

                    <div className="login-with-email-border"></div>
                  </div>

                  <Formik
                    initialValues={adsInformationInitialValue}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // console.log("valuesokkkk", values);
                      handleOnSubmitRegisterForm(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      dirty,
                      setValues,
                      setErrors,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="mt-3 input-login cursor">
                          <input
                            required
                            name="name"
                            className="input-sigin-1"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </div>

                        <div className="mt-3 input-login cursor">
                          <input
                            required
                            name="email"
                            className="input-sigin-1"
                            type="email"
                            placeholder="Email Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </div>

                        <PhoneInput
                          country={"us"}
                          containerClass="mt-3 cursor"
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                          }}
                          onChange={(phone, country) =>
                            setValues({
                              ...values,
                              phoneNumber: phone,
                            })
                          }
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                        />

                        <div className="mt-3 input-login rel cursor">
                          <input
                            required
                            name="password"
                            className="input-sigin-1"
                            type={show == true ? "text" : "password"}
                            // type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <span
                            className="mobile-eye-password"
                            onClick={handelShowpassword}
                          >
                            {!show ? (
                              <LuEye color="#191F33" size={20} />
                            ) : (
                              <FaRegEyeSlash color="#191F33" size={20} />
                            )}
                          </span>
                        </div>

                        <div className="d-flex align-items-center mt-3 f-14 fw-400">
                          <input
                            required
                            className="input-checkbox cursor"
                            name="tnc"
                            type="checkbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tnc}
                          />

                          <span
                            style={{ color: "#636A80" }}
                            className="f-14 ms-2 cursor"
                          >
                            I've read and agree with your{" "}
                            <span className="c-orange">Privacy Policy</span> and{" "}
                            <span>
                              <NavLink
                                to="/terms-and-conditions"
                                className="c-orange"
                              >
                                Terms & Conditions
                              </NavLink>
                            </span>
                          </span>
                        </div>

                        <button
                          id="register-button"
                          disabled={!isValid || !dirty}
                          type="submit"
                          className="my-button padd-btn-login w-100 mt-4 cursor"
                        >
                          Sign Up <GoArrowRight size={20} />
                        </button>
                      </form>
                    )}
                  </Formik>

                  <div className="mt-3 t-center f-16 fw-600">
                    Already have an account?{" "}
                    <NavLink to="/login">
                      <span className="c-orange cursor"> Sign In</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default Register;
