import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { IoSearchOutline } from "react-icons/io5";
import { MyOrdersLoader } from "../../../components/loader/AdsItemLoader";
import OrdersItems from "./OrdersItems";
import { Table } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";

var searchTimeout;

const ReceivedOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);
  const [activeFilters, setActiveFilters] = useState({});

  const loadReceivedOrders = async (
    isLoadMore = false,
    hasExtraPayload = {}
  ) => {
    try {
      if (!isLoadMore) {
        setLoader(true);
      }
      const options = {
        showLoader: "load-more",
      };

      const data = { ...activeFilters, ...hasExtraPayload };

      const response = await PostRequest(
        "/user/rent-sell/orders",
        data,
        options
      );

      if (isLoadMore) {
        setOrders((prev) => [...prev, ...response]);
      } else {
        setOrders(response);
      }

      if (response.length < 20) {
        setHasLoadMore(false);
      } else {
        setHasLoadMore(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      if (!isLoadMore) {
        setLoader(false);
      }
    }
  };

  const handelOnChangeOrderType = async (type) => {
    setActiveFilters((prev) => ({ ...prev, orderType: type }));
  };

  const handleSearchOrder = (event) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setActiveFilters((prev) => ({ ...prev, search: event.target.value }));
    }, 500);
  };

  const handelOnChangeStatus = async (status) => {
    try {
      setActiveFilters((prev) => ({
        ...prev,
        status: status == "All" ? null : status,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const loadMoreReceivedOrders = () => {
    const data = {
      skip: orders.length,
    };

    loadReceivedOrders(true, data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadReceivedOrders();
  }, [activeFilters]);

  return (
    <DefaultDashboard title="Received Orders">
      <div className="bg-user-post-ad p-3">
        <div className="row">
          <div className="col-md-5">
            <div className="input-head">
              <IoSearchOutline size={20} color="#F59449" />
              <input
                type="text"
                className="input-search ms-2"
                placeholder="Ads tittle, keyword..."
                onChange={handleSearchOrder}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="offset-md-4 col-md-4 mobile-mt-2">
                <select
                  name=""
                  onChange={(e) => handelOnChangeOrderType(e.target.value)}
                  className="my-input c-black"
                  id=""
                >
                  <option value="All">All</option>
                  <option value="Sell">Buy</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>

              <div className="col-md-4 mobile-mt-2">
                <select
                  onChange={(e) => handelOnChangeStatus(e.target.value)}
                  className="my-input c-black"
                >
                  <option value="All">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Running">Running</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Canceled">Canceled</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Settlement">Settlement</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="gap-30">
          <div className="row">
            <Table className="user-ads-list-table" hover responsive>
              <thead>
                <tr>
                  <th className="th-title-50">Order</th>
                  <th>Date</th>
                  <th>Prices</th>
                  <th>Status</th>
                  <th style={{ width: "12%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <MyOrdersLoader />
                ) : (
                  <Fragment>
                    {orders.map((order, index) => {
                      return (
                        <OrdersItems
                          type="ReceivedOrder"
                          key={index}
                          orderItem={order}
                        />
                      );
                    })}
                  </Fragment>
                )}

                {loader ? (
                  ""
                ) : (
                  <Fragment>
                    {orders.length == 0 && (
                      <tr className="t-center">
                        <td colSpan={5}>
                          <h5 className="c-gray mt-3">"0" orders received.</h5>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )}
              </tbody>
            </Table>
            {loader ? (
              ""
            ) : (
              <Fragment>
                {hasLoadMore && orders.length != 0 && (
                  <div className="t-center">
                    <button
                      id="load-more"
                      onClick={loadMoreReceivedOrders}
                      className="my-button fw-600 mt-3 w-25 loadMore-btn cursor"
                    >
                      Load More
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default ReceivedOrders;
