import React from "react";
// import { Suspense, lazy } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";

import Faq from "./pages/faq/Faq";
import AboutUs from "./pages/about/AboutUs";
import MyAds from "./pages/user/dashboard/MyAds";
import TeamsAndConditions from "./pages/TeamsAndConditions";
import UserOrders from "./pages/user/orders/UserOrders";

import { ToastContainer } from "react-toastify";
import PlaceOrder from "./pages/order/PlaceOrder";
import ReceivedOrders from "./pages/user/orders/ReceivedOrders";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import CreateNewPost from "./pages/user/dashboard/CreateNewPost";
import UserDashboard from "./pages/user/dashboard/UserDashboard";
import MyFavoriteAds from "./pages/user/dashboard/MyFavoriteAds";
import UserProfileDetails from "./pages/user/account/UserProfileDetails";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./store/user/user.redux";
import { Provider } from "react-redux";
import OrdersDetails from "./pages/user/orders/OrdersDetails";
import ProductDetail from "./pages/product/ProductDetail";
import HowItWorkPage from "./pages/howitwork/HowItWorkPage";
import SellerProfile from "./pages/seller/SellerProfile";
import UserChats from "./pages/user/message/UserChats";
import Verification from "./pages/auth/Verification";
import ResetPassword from "./pages/auth/ResetPassword";
import ProfileVerification from "./components/user/profile-verification/ProfileVerification";
import ForgotPassPage from "./pages/auth/ForgotPassPage";
import ListingsProducts from "./pages/product/productlist/ListingsProducts";

import UploadDocumentCameraPage from "./pages/user/document/UploadDocumentCameraPage";
import FeaturesPage from "./pages/Features/FeaturesPage";
import UpdatePost from "./pages/user/dashboard/UpdatePost";
import WebBrowser from "./components/user/profile-verification/WebBrowser";
import DocumentUploadComponents from "./components/user/profile-verification/DocumentUploadComponents";
import PaymentsDetails from "./pages/user/payment/PaymentsDetails";
import VerificationPhoneNumber from "./pages/auth/VerificationPhoneNumber";
import MyNotification from "./pages/user/mynotification/MyNotification";
import UploadOrderImagesByQR from "./pages/order/orderPhotos/UploadOrderImagesByQR";
import PaymentSettings from "./pages/user/payment/PaymentSettings";
import CouponsPage from "./pages/coupons/CouponsPage";
import Feedback from "./pages/feedback/Feedback";

import "./App.css";
const clientId =
  "88613061289-h21s9prd35oka29cbb2vrcg56m32elsb.apps.googleusercontent.com";

const App = () => {
  const rootReducer = combineReducers({
    userReducer: userReducer,
  });

  const myStore = configureStore({
    reducer: rootReducer,
  });

  return (
    <div>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={myStore}>
          <BrowserRouter>
            <Routes>
              {/* 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 */}

              <Route path="/" element={<Home />} />

              <Route path="/home" element={<Home />} />

              <Route path="/login" element={<Login />} />

              <Route path="/register" element={<Register />} />

              <Route path="/verification" element={<Verification />} />

              <Route
                path="/user/phone/update"
                element={<VerificationPhoneNumber />}
              />

              <Route path="/forgot-password" element={<ForgotPassPage />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/secure-transactions" element={<FeaturesPage />} />
              <Route
                path="/terms-and-conditions"
                element={<TeamsAndConditions />}
              />

              <Route path="/how-it-works" element={<HowItWorkPage />} />
              <Route path="/user/notifications" element={<MyNotification />} />

              <Route path="/user/dashboard" element={<UserDashboard />} />

              {/* Create Products */}

              <Route path="/user/post/create" element={<CreateNewPost />} />
              <Route path="/user/post/edit/:id" element={<UpdatePost />} />

              {/* product */}

              <Route path="/ads/listings" element={<ListingsProducts />} />
              <Route
                path="/ads/listings/:category"
                element={<ListingsProducts />}
              />
              <Route path="/ads/:slug/:id" element={<ProductDetail />} />
              <Route path="/user/ads" element={<MyAds />} />

              {/* orders */}
              <Route
                path="/ads/:slug/:id/placeorder"
                element={<PlaceOrder />}
              />
              <Route path="/user/orders" element={<UserOrders />} />

              <Route path="/user/coupon" element={<CouponsPage />} />

              <Route
                path="/user/orders/details/:orderNumber"
                element={<OrdersDetails />}
              />

              <Route
                path="/user/sell-rent/orders"
                element={<ReceivedOrders />}
              />

              {/* user verification */}
              <Route
                path="/user/profile/verify"
                element={<ProfileVerification />}
              />

              <Route
                path="/user/profile/verify/country/:token"
                element={<WebBrowser />}
              />

              <Route
                path="/user/order/media/upload/:user/:order/:type"
                element={<UploadOrderImagesByQR />}
              />

              <Route
                path="/user/document-verification/:token"
                element={<UploadDocumentCameraPage />}
              />

              <Route
                path="/user/document-verification/proof/:token"
                element={<UploadDocumentCameraPage />}
              />

              <Route
                path="/user/document-upload/thankyou"
                element={<DocumentUploadComponents />}
              />

              <Route
                path="/user/profile/settings"
                element={<UserProfileDetails />}
              />
              <Route
                path="/user/payment/details"
                element={<PaymentsDetails />}
              />

              <Route
                path="/user/payment/settings"
                element={<PaymentSettings />}
              />

              <Route
                path="/user/payment/settings/:account"
                element={<PaymentSettings />}
              />

              <Route
                path="/seller/:slug/:id/profile"
                element={<SellerProfile />}
              />

              <Route path="/user/favorite" element={<MyFavoriteAds />} />

              <Route path="/user/messages" element={<UserChats />} />
              <Route path="/user/messages/:id" element={<UserChats />} />

              <Route
                path="/feedback/:user/:order/:token"
                element={<Feedback />}
              />
            </Routes>
          </BrowserRouter>
        </Provider>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* <Notification /> */}
      </GoogleOAuthProvider>
    </div>
  );
};

export default App;
