import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaArrowRightLong } from "react-icons/fa6";
import { Country, State, City } from "country-state-city";
import { getAuthUser, showToaster } from "../../../config/common";
import PhoneInput from "react-phone-input-2";
import GoogleMapReact from "google-map-react";
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GoogleAddressAutocomplete from "./map/GoogleAddressAutocomplete";

// Validation Schema
const validationSchema = Yup.object().shape({});

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;

// console.log("loginUser", loginUser);

const AdsInfomationAddress = (props) => {
  const {
    handleOnChangeTab,
    // handelOnSubmitPostAdd,
    handleOnSubmitData,
    activeDefaultValue = {},
    isEdit = false,
  } = props;

  const loginUser = getAuthUser();
  // console.log("activeDefaultValue", activeDefaultValue);

  // Initial Values
  const adsInformationInitialValue = {
    phoneNumber:
      activeDefaultValue?.contactDetails?.phoneNumber || loginUser?.phoneNumber,
    emailAddress:
      activeDefaultValue?.contactDetails?.emailAddress || loginUser?.email,
  };

  const [selectedAddress, setSelectedAddress] = useState(false);
  // console.log("selectedAddress", selectedAddress);

  const [marker, setMarker] = useState(false);

  const handlePlaceSelected = (place) => {
    setSelectedAddress(place);
  };

  return (
    <div className={`${isEdit ? "p-3" : "p-20"} `}>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // if(selectedAddress){
          values = { ...values, ...selectedAddress };
          values.latitude = selectedAddress?.lat;
          values.longitude = selectedAddress?.lng;

          if (!values.address || values.address.trim() == "") {
            showToaster("Please select your address.", "error");
            return false;
          }

          handleOnSubmitData(values);

          handleOnChangeTab(4);
          // } else{

          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="fw-600 f-16">Email Address</div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emailAddress}
                  required
                  name="emailAddress"
                  className="my-input mt-1"
                  type="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="col-md-6">
                <div className="fw-600 f-16">Phone Number</div>
                <PhoneInput
                  country={"us"}
                  containerClass="cursor mt-1"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                  }}
                  onChange={(phone, country) =>
                    setValues({
                      ...values,
                      phoneNumber: phone,
                    })
                  }
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="fw-600 f-16">Address</div>

                <GoogleAddressAutocomplete
                  onPlaceSelected={handlePlaceSelected}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setSelectedAddress(false);
                    }
                  }}
                  placeholder="Search for a place"
                  defaultValue={activeDefaultValue}
                />
              </div>
            </div>

            {isEdit ? (
              <div className="gap-50 t-end">
                <button
                  id="address-loader"
                  type="submit"
                  className="coman-btn ms-3 cursor"
                >
                  Save Changes
                </button>
              </div>
            ) : (
              <div className="gap-50 t-end">
                <button
                  type="button"
                  onClick={() => handleOnChangeTab(2)}
                  className="coman-btn-boder"
                >
                  Previous
                </button>

                <button
                  id="postUpload-btn"
                  type="submit"
                  // disabled={!isValid || !dirty}
                  className="coman-btn ms-3"
                >
                  Next Step &nbsp;
                  <FaArrowRightLong />
                </button>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdsInfomationAddress;
