import React, { useEffect, useState } from "react";
import Default from "../../../components/default/Default";
import Banner from "../../../components/header/Banner";
import NotificationComponents from "../../../components/notification/NotificationComponents";
import NotificationItems from "../../../components/notification/NotificationItems";
import PostRequest from "../../../services/PostRequest";
import LottieLoader from "../../../components/lottieloader/LottieLoader";

const MyNotification = (props) => {
  const [notifications, setNotifications] = useState(false);
  const loadNotificationCount = async () => {
    try {
      const response = await PostRequest("/notifications");

      setNotifications(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    loadNotificationCount();
  }, []);

  if (!notifications) {
    return <LottieLoader />;
  }

  return (
    <Default>
      <section>
        <Banner
          title={props.title || "Notification"}
          pagename={props.title || "Notification"}
        />
      </section>

      <div className="container mt-4">
        {notifications.map((notification, index) => {
          return (
            <div
              key={index}
              style={{ borderBottom: "1px solid #ebe9e9" }}
              className="mt-3"
            >
              <NotificationItems notification={notification} />
            </div>
          );
        })}

        {notifications.length == 0 && (
          // <div className="fw-600 f-16 text-center">No notifications</div>
          <h5 className="text-center fw-600 ">"0" notifications</h5>
        )}
      </div>
    </Default>
  );
};

export default MyNotification;
