import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/header/Banner";
import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
import { LuEye } from "react-icons/lu";
import { FaRegEyeSlash } from "react-icons/fa";
import OTPInput from "react-otp-input";
import Default from "../../components/default/Default";

const ResetPassword = () => {
  const location = useLocation();
  const token = location.state;
  // console.log("token", token);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [otp, setOtp] = useState("");

  const [isOtpVerify, setIsOtpVerify] = useState(false);

  const handelResetPassword = async (event) => {
    try {
      event.preventDefault();

      if (isOtpVerify == false) {
        try {
          const data = {
            token: token,
            otp: otp,
          };

          const options = {
            showLoader: "reset-button",
          };
          await PostRequest("/verify-otp", data, options);

          setIsOtpVerify(true);
        } catch (error) {
          // console.log("error111",error.message);
          showToaster(error.message, "error");
        }
      } else {
        try {
          const formData = new FormData(event.target);

          var data = {};

          formData.forEach(function (value, key) {
            data[key] = value;
          });

          data.token = token;

          const options = {
            showLoader: "reset-button",
          };

          const response = await PostRequest("/reset-password", data, options);

          navigate("/login");
          showToaster(" Your password has been successfully changed");
        } catch (error) {}
      }
    } catch (error) {
      // console.log("error", error);
      showToaster(error.message, "error");
    }
  };

  const handelShowpassword = () => {
    setShow(!show);
  };

  return (
    <Default>
      <section>
        <Banner title="Reset Password" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-in-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <form onSubmit={handelResetPassword}>
                  <div className="login-bg">
                    <div className="t-center fw-600 mt-3">
                      <h3>Reset Password</h3>
                    </div>

                    <div className="fw-600 mt-5 f-16 t-center c-gray">
                      To secure your account, please enter the OTP sent to your
                      email, <br /> along with your new password.
                    </div>

                    <div
                      className="w-100"
                      style={{
                        textAlign: "center",

                        margin: "auto",
                        marginTop: "50px",
                      }}
                    >
                      {isOtpVerify == false && (
                        <div className="mt-5">
                          <OTPInput
                            inputStyle={{
                              height: "60px",
                              width: "100%",
                              border: "1px solid #F8A200",
                              outline: "none",
                              borderRadius: "5px",
                              marginRight: "20px",
                              marginLeft: "10px",
                            }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      )}

                      {isOtpVerify == true && (
                        <div className="mt-4 input-login cursor rel">
                          <input
                            required
                            name="password"
                            className="input-sigin-1"
                            type={show == true ? "text" : "password"}
                            placeholder="New Password"
                          />

                          <span
                            className="eye-password"
                            onClick={handelShowpassword}
                          >
                            {!show ? (
                              <LuEye color="#191F33" size={20} />
                            ) : (
                              <FaRegEyeSlash color="#191F33" size={20} />
                            )}
                          </span>
                        </div>
                      )}

                      {isOtpVerify == false ? (
                        <button
                          id="reset-button"
                          className="my-button padd-btn-login w-100 mt-5 cursor"
                        >
                          Verify OTP
                        </button>
                      ) : (
                        <button
                          id="reset-button"
                          className="my-button padd-btn-login w-100 mt-5 cursor"
                        >
                          Reset Password
                        </button>
                      )}

                      <div className="mt-3 t-center f-16 fw-400 c-gray">
                        You remember your password
                        <NavLink to="/login">
                          <span className="c-orange cursor"> Login?</span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default ResetPassword;
