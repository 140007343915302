import React, { useEffect, useState } from "react";
import Banner from "../header/Banner";

import { getAuthUser } from "../../config/common";
import UserAvatar from "../user/UserAvatar";

import Default from "../default/Default";
import UserSidebar from "../user/UserSidebar";
import { useNavigate } from "react-router-dom";
import PostRequest from "../../services/PostRequest";
import "./default-dashboard.css";
import DocumentVerificationStatus from "../user/profile-verification/DocumentVerificationStatus";
import { Modal } from "react-bootstrap";
const DefaultDashboard = (props) => {
  const navigate = useNavigate();
  const loginUser = getAuthUser();

  const [showMenu, setShowMenu] = useState(false);

  // const [ user , setUser ] = useState(loginUser)

  const [activeUser, setActiveUser] = useState(loginUser);
  const [documentVerificationStatus, setDocumentVerificationStatus] =
    useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    window.localStorage.setItem("payment-prompt", true);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handelShowMenuList = () => {
    setShowMenu(!showMenu);
  };

  const loadUserProfile = async () => {
    try {
      const response = await PostRequest("/profile");

      setActiveUser(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleUserVerified = async () => {
    try {
      await PostRequest("/profile/verified");
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const loadDocumentVerificationStatus = async () => {
    try {
      const response = await PostRequest("/user/documents/status");

      setDocumentVerificationStatus(response);
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  const loadUserPayments = async () => {
    try {
      const response = await PostRequest("/user/payment-setting");
      if (
        !response ||
        (response &&
          !response.defaultPaymentMethod &&
          !window.location.href.match("user/payment/settings"))
      ) {
        setShow(true);
      }

      // setDocumentVerificationStatus(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handelPaymentDetails = () => {
    navigate("/user/payment/details");
    setShow(false);
  };

  useEffect(() => {
    loadUserProfile();
    loadDocumentVerificationStatus();

    if (!window.localStorage.getItem("payment-prompt")) {
      loadUserPayments();
    }
  }, []);

  useEffect(() => {
    if (
      activeUser &&
      documentVerificationStatus &&
      documentVerificationStatus.documentVerificationStatus == "Verified" &&
      activeUser.documentVerificationStatus != "Verified"
    ) {
      setActiveUser((prev) => ({
        ...prev,
        documentVerificationStatus: "Verified",
      }));
      // Update Profile For Verification
      handleUserVerified();
    }
  }, [documentVerificationStatus, activeUser]);

  useEffect(() => {
    if (
      documentVerificationStatus &&
      window.location.href.match("user/profile/verify") &&
      !["Init", "Pending", "Rejected"].includes(
        documentVerificationStatus.documentVerificationStatus
      )
    ) {
      navigate("/user/dashboard");
    }
  }, [documentVerificationStatus]);

  useEffect(() => {
    window.localStorage.setItem("auth", JSON.stringify(activeUser));
  }, [activeUser]);

  return (
    <Default>
      <section>
        <Banner
          title={props.title || "Dashboard"}
          pagename={props.title || "Dashboard"}
        />
      </section>

      <section>
        <div className="container gap-40">
          <div className="row">
            <div className="col-md-12 t-center">
              <DocumentVerificationStatus
                status={
                  documentVerificationStatus
                    ? documentVerificationStatus.documentVerificationStatus
                    : false
                }
                rejectMessage={
                  documentVerificationStatus
                    ? documentVerificationStatus.rejectReason
                    : ""
                }
                // status={"Verified"}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-3">
              <div className="hidden-in-mobile">
                <UserSidebar activeUser={activeUser} />
              </div>
            </div>
            <div className="col-md-9">{props.children}</div>
          </div>

          <div className="user-profile-bottom hidden-in-desktop mobile-block">
            <div onClick={handelShowMenuList}>
              <UserAvatar
                size="50"
                url={loginUser?.profileImage}
                name={loginUser?.name}
              />
            </div>
          </div>

          {showMenu && (
            <div className="user-sidebar-in-mobile">
              <UserSidebar activeUser={activeUser} />
            </div>
          )}
        </div>
      </section>
      {/* payment detail not  model */}

      <div className="seller-model">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Setup Payments for Earning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please ensure your payment details, including your PayPal account
              information, are up-to-date and correctly configured to receive
              your payouts without delay. Providing the required bank account
              details is essential for successfully selling items, but not
              necessary for buying or renting.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="t-center">
              <button
                onClick={handelPaymentDetails}
                className="coman-btn cursor"
              >
                Setup Payments Now
              </button>

              <button onClick={handleClose} className="coman-btn cursor ms-2">
                I am Buyer
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </Default>
  );
};

export default DefaultDashboard;
