import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  getAuthUser,
  isMobile,
  QRCodeGeneratorForOrder,
} from "../../../config/common";
import QRCode from "react-qr-code";
import { Modal } from "react-bootstrap";
import WebCameraComponents from "./WebCameraComponents";
import PostRequest from "../../../services/PostRequest";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { PhotosLoader } from "../../../components/loader/AdsItemLoader";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
const QrMediaUpload = ({ orderDetails }) => {
  const loginUser = getAuthUser();

  const [takeDocument, setTakeDocument] = useState(false);
  const [openScreen, setOpenScreen] = useState(false);
  const [orderPhotos, setOrderPhotos] = useState(false);
  const [loader, setLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const timerRef = useRef(null); // useRef to store the timer

  const closeModel = () => {
    setTakeDocument(false);
    if (timerRef.current) {
      clearInterval(timerRef.current); // Clear the interval when the modal closes
    }
  };

  const show = () => setTakeDocument(true);

  const handelTakePhoto = () => {
    try {
      if (isMobile()) {
        setOpenScreen(true);
      } else {
        setTakeDocument(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadProductPhots = async () => {
    try {
      const data = {
        order: orderDetails._id,
        type: orderDetails.status,
      };

      const response = await PostRequest("/order/photos", data);
      setOrderPhotos(response);
      setRefresh((prev) => !prev); // Toggle refresh state to trigger re-render
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (takeDocument) {
      // Start the interval when modal opens
      timerRef.current = setInterval(() => {
        loadProductPhots();
      }, 2500);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clear interval on component unmount
      }
    };
  }, [takeDocument]);

  return (
    <Fragment>
      <button
        data-tooltip-id="my-tooltip"
        onClick={handelTakePhoto}
        className="outline-btn ms-2"
      >
        Take Photos
      </button>
      <ReactTooltip
        id="my-tooltip"
        place="top"
        content="Upload photos to display the item's current condition for security deposit management."
        style={{ width: "200px" }}
      />

      <Modal show={takeDocument} onHide={closeModel} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload Photos (Max 6. Photos)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-5">
          {/* LOADER */}

          {loader ? (
            <PhotosLoader />
          ) : (
            <Fragment>
              {/* {orderPhotos && orderPhotos?.photos?.length !== 0 && (
                <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                  {orderPhotos?.photos?.map((image, index) => {
                    return (
                      <img
                        className="m-2"
                        height={100}
                        width={100}
                        key={index}
                        src={image}
                        alt=""
                      />
                    );
                  })}
                </SlideshowLightbox>
              )} */}

              {orderPhotos?.photos?.map((image, index) => {
                return (
                  <img
                    className="m-2"
                    height={100}
                    width={100}
                    key={index}
                    src={image}
                    alt="image"
                  />
                );
              })}
            </Fragment>
          )}

          {(!orderPhotos ||
            (orderPhotos && orderPhotos?.photos.length < 6)) && (
            <Fragment>
              {orderPhotos && orderPhotos.photos?.length !== 0 && (
                <Fragment>
                  <hr />
                  <div className="t-center">Or Add More Photos</div>
                  <hr />
                </Fragment>
              )}

              <div className="d-center mt-4">
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 100,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    id="qr"
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={QRCodeGeneratorForOrder(
                      orderDetails._id,
                      orderDetails.status
                    )}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>

              <p className="text-center mt-4">
                Please scan the QR code on your mobile device to upload photos
                of your items along with the order. This step helps ensure that
                any potential damages or incorrect items are identified and
                resolved promptly.
              </p>
            </Fragment>
          )}
        </Modal.Body>
      </Modal>

      {openScreen && (
        <WebCameraComponents
          type={orderDetails.status}
          user={loginUser?._id}
          order={orderDetails._id}
        />
      )}
    </Fragment>
  );
};

export default QrMediaUpload;
