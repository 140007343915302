import { useState } from "react";
import PaypalPaymentMethod from "../../../components/payments/paypal/PaypalPaymentMethod";
import {
  handlePapalPaymentInit,
  handlePaypalPaymentApproved,
} from "../../../config/common";

export default function PaymentButton({
  totalAmount,
  onPaymentSuccess,
  payment,
  numberOfDays,
  coupon = false,
}) {
  // console.log("totalAmount1010", totalAmount);

  const [isPaymentStarted, setIsPaymentStarted] = useState(false);

  const onApprove = async (data) => {
    // console.log('payment',data)

    try {
      setIsPaymentStarted(true);
      const response = await handlePaypalPaymentApproved({
        ...data,
        amount: totalAmount,
      });

      onPaymentSuccess(response);
    } catch (error) {
    } finally {
      setIsPaymentStarted(false);
    }
  };

  return (
    <div>
      {isPaymentStarted && (
        <div className="text-center">
          <h4> Please Wait </h4>
          <span className="c-gray">
            {" "}
            Verifying Payment, Please don't refresh page or close browser.{" "}
          </span>
        </div>
      )}

      {!isPaymentStarted && (
        <PaypalPaymentMethod
          createOrder={() =>
            handlePapalPaymentInit({
              totalAmount,
              ad: payment._id,
              numberOfDays,
              coupon,
            })
          }
          onApprove={onApprove}
        />
      )}
    </div>
  );
}
