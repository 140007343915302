import React, { Fragment, useEffect, useState } from "react";

import Banner from "../../components/header/Banner";
import { CiLocationOn } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import { FaHandsHelping } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";

import { AiFillHeart, AiOutlineMessage } from "react-icons/ai";
import ImageGalleryComponent from "../../components/swiper/imagegallery/ImageGalleryComponent";
import ProductItem from "../../components/products/productItem/ProductItem";
import { FaCircleCheck, FaStar } from "react-icons/fa6";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import PostRequest from "../../services/PostRequest";

import moment from "moment";
import {
  formatter,
  getAuthUser,
  showToaster,
  toSlug,
} from "../../config/common";
import { ProductDetailLoader } from "../../components/loader/AdsItemLoader";
import { GoAlert } from "react-icons/go";
import LottieLoader from "../../components/lottieloader/LottieLoader";
import Default from "../../components/default/Default";
import GoogleMapReact from "google-map-react";
import UserAvatar from "../../components/user/UserAvatar";

import "./productdetail.css";
import { Modal } from "react-bootstrap";
import LoginFormComponent from "../../components/LoginFormComponent";

const Circle = ({ lat, lng }) => {
  const getRadiusInPixels = (radiusInMeters, zoomLevel) => {
    const scale = 1 << zoomLevel;
    const earthCircumference = 40075017; // in meters
    const pixelSizeAtEquator = earthCircumference / 256;
    return (radiusInMeters / pixelSizeAtEquator) * scale;
  };

  const radius = getRadiusInPixels(10000, 10);

  return (
    <div
      style={{
        position: "absolute",
        width: `${radius * 2}px`,
        height: `${radius * 2}px`,
        backgroundColor: "#e9a62b73", // Fill color with opacity
        border: "2px solid orange", // Stroke color
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
      }}
    ></div>
  );
};

const ProductDetail = () => {
  const location = useLocation();
  const productItem = location.state;

  const params = useParams();

  // console.log("params",params);

  const navigate = useNavigate();

  const [productDetails, setProductDetails] = useState(productItem);

  // console.log("productDetails", productDetails);

  const [isInFavorite, setIsInFavorite] = useState(false);

  const [relativeProducts, setRelativeProducts] = useState([]);

  // console.log("relativeProducts", relativeProducts);
  const [loader, setLoader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loginUser = getAuthUser();

  const loadAdsProductDetails = async () => {
    try {
      setLoader(true);
      const data = {
        ad: params.id,
      };
      const response = await PostRequest("/ads/details", data);

      setProductDetails(response);
      setIsInFavorite(response.isInUserFav);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handelFavorite = async () => {
    try {
      const data = {
        ad: params.id,
      };
      setIsInFavorite(!isInFavorite);
      const response = await PostRequest("/favorite/add-remove", data);
      //  showToaster(response.message)
    } catch (error) {}
  };

  const handelSellerChat = () => {
    navigate(
      `/user/messages/${productDetails.user._id}?ad=${productDetails._id}`
    );
  };

  const loadRelativeProduct = async () => {
    try {
      const data = {
        subCategory: productDetails.subCategory,
      };

      const response = await PostRequest("/relative/ads", data);

      // console.log("response",response);

      setRelativeProducts(response);
    } catch (error) {}
  };

  const placeOrderNavigate = () => {
    navigate(`/ads/${productDetails.slug}/${productDetails._id}/placeorder`, {
      state: productDetails,
    });
  };

  const handelCallBack = () => {
    handleClose();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    loadAdsProductDetails();
  }, [params.id]);

  useEffect(() => {
    loadRelativeProduct();
  }, []);

  if (!productDetails) {
    return <LottieLoader />;
  }

  if (!relativeProducts) {
    return null;
  }

  return (
    <Default>
      <section>
        <Banner title={productDetails?.title} />
      </section>

      {loader ? (
        <ProductDetailLoader />
      ) : (
        <div>
          <section>
            <div className="container mt-4">
              <div className="row">
                <div className="col-md-8">
                  <div className="c-black f-32 fw-600 mobile-font-20">
                    {productDetails.title}
                  </div>

                  <div className="f-16 mt-1 fw-400 c-gray mobile-font-15 mobile-mt-2">
                    <div className="mb-2 mobile-mb-10">
                      <span className="promote-btn-detail">FEATURED</span>
                    </div>

                    {productDetails.contactDetails?.address != "" && (
                      <Fragment>
                        <span className="ms-2">
                          <CiLocationOn size={20} />
                        </span>
                        <span className="ms-2">
                          {productDetails?.contactDetails?.address}
                        </span>
                      </Fragment>
                    )}

                    <span className="ms-2">
                      {" "}
                      <CiClock2 size={20} />
                    </span>
                    <span className="ms-2 ">
                      {moment().format("MMMM Do h:mm a")}
                    </span>
                  </div>

                  <div className="mt-4 mobile-mt-3">
                    <ImageGalleryComponent data={productDetails?.images} />
                  </div>

                  <div className="mt-4">
                    <div className="f-24 c-black fw-600">Descriptions</div>

                    <div className="mt-2 c-gray f-16">
                      {productDetails?.description}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="product-saidbar mobile-mt-2">
                    <div className="padd-said-15 mt-1 d-flex justify-content-between align-items-center">
                      <div className="f-32 c-black fw-400">
                        {formatter.format(productDetails.price)}{" "}
                        {productDetails?.transactionType == "Rent" && (
                          <span className="f-15 c-gray">
                            / {productDetails.priceType}
                          </span>
                        )}
                      </div>

                      <div
                        className="heart-bg cursor"
                        onClick={loginUser ? handelFavorite : handleShow}
                      >
                        {isInFavorite ? (
                          <AiFillHeart size={30} />
                        ) : (
                          <CiHeart size={30} />
                        )}
                      </div>
                    </div>

                    <div className="boder-saidbar-pd mt-1"></div>

                    {productDetails?.user?._id != loginUser?._id && (
                      <div className="mt-1 padd-said-15">
                        <button
                          onClick={handelSellerChat}
                          className="my-button btn-orange-outline w-100 f-16 fw-700  mt-3 padd-12"
                        >
                          {" "}
                          <AiOutlineMessage size={23} />{" "}
                          <span className="ms-1 mt-1">Send Message</span>
                        </button>

                        {productDetails.status == "Available" ? (
                          <Fragment>
                            {productDetails.transactionType == "Sell" ? (
                              <button
                                onClick={
                                  loginUser ? placeOrderNavigate : handleShow
                                }
                                className="my-button bg-theme  w-100 f-16 fw-700  mt-3 padd-12"
                              >
                                {" "}
                                <FaHandsHelping size={25} />{" "}
                                <span className="ms-1 mt-1">Buy This Item</span>
                              </button>
                            ) : (
                              <button
                                onClick={
                                  loginUser ? placeOrderNavigate : handleShow
                                }
                                className="my-button bg-theme  w-100 f-16 fw-700  mt-3 padd-12"
                              >
                                {" "}
                                <FaHandsHelping size={25} />{" "}
                                <span className="ms-1 mt-1">
                                  Rent This Item
                                </span>
                              </button>
                            )}
                          </Fragment>
                        ) : (
                          <label className="mt-3 text-danger fw-600 ms-1">
                            This item is not available
                          </label>
                        )}
                      </div>
                    )}

                    <div className="boder-saidbar-pd mt-2"></div>

                    <div className="mt-1 padd-said-15 ">
                      {/* SHOW ONLY IF USER THER */}
                      {productDetails?.user && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <div>
                              {/* <img src="/images/userimg.png" alt="" /> */}

                              <UserAvatar
                                round
                                name={productDetails?.user.name}
                                url={productDetails?.user.profileImage}
                                size="50"
                              />
                            </div>
                            <div className="ms-3">
                              <div className="f-14 fw-400 c-gray ">Add by:</div>
                              <div className="f-16 fw-600">
                                {productDetails?.user.name}

                                {productDetails?.user.isVerifiedUser && (
                                  <span className="c-green ms-1">
                                    <FaCircleCheck />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="f-16 fw-600 c-orange cursor">
                              <NavLink
                                to={`/seller/${toSlug(
                                  productDetails?.user.name
                                )}/${productDetails.user._id}/profile`}
                                state={productDetails.user}
                                className="c-orange"
                              >
                                View Profile
                              </NavLink>
                            </div>

                            <div className="d-flex align-items-center">
                              <FaStar className="c-orange" />
                              <div className="mt-1">
                                <span className="ms-1">
                                  {!productDetails.user.averageRating ? (
                                    "0"
                                  ) : (
                                    <Fragment>
                                      {productDetails?.user?.averageRating}
                                    </Fragment>
                                  )}
                                </span>
                                <span
                                  style={{ color: "gray" }}
                                  className="c-gray-dark"
                                >
                                  ({productDetails?.user?.totalRating})
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {productDetails?.user != null && (
                        <Fragment>
                          {productDetails?.user?.email != "" && (
                            <div className="mt-4 cursor">
                              {productDetails?.user?.email != "" && (
                                <div>
                                  <span>
                                    <TfiEmail size={19} className="c-orange" />
                                  </span>
                                  <span className="ms-2 c-gray f-16 fw-400">
                                    {productDetails?.user?.email}
                                  </span>
                                </div>
                              )}

                              {productDetails.contactDetails?.address != "" && (
                                <div className="mt-2 cursor text-one-line">
                                  <span className="">
                                    <SlLocationPin
                                      size={20}
                                      className="c-orange"
                                    />
                                  </span>
                                  <span className="ms-2 c-gray f-16 fw-400">
                                    {productDetails?.contactDetails.address}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className="boder-saidbar-pd mt-2"></div>

                    {productDetails.contactDetails?.location && (
                      <div className="mt-1 padd-said-15">
                        <div className="f-20 fw-400">Ads Location</div>
                        <div
                          style={{
                            width: "100%",
                            height: "350px",
                            margin: "0px",
                            borderRadius: "10px",
                            padding: "0px",
                            position: "relatives",
                            overflow: "hidden",
                          }}
                          className="mt-3"
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyB44pxd0_owXRfMD3I7Ep_F4YRqrd3rXQo",
                            }}
                            defaultCenter={{
                              lat: productDetails.contactDetails?.location
                                ?.latitude,
                              lng: productDetails.contactDetails?.location
                                ?.longitude,
                            }}
                            defaultZoom={10}
                          >
                            <Circle
                              lat={
                                productDetails.contactDetails?.location
                                  ?.latitude
                              }
                              lng={
                                productDetails.contactDetails?.location
                                  ?.longitude
                              }
                              radius={10}
                            />
                          </GoogleMapReact>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mt-4 product-saidbar">
                    <div className="padd-said-15">
                      <div className="f-24 fw-600">Overview</div>

                      <div className="mt-3 d-flex justify-content-between align-items-center">
                        <div className=" f-16 fw-400">
                          <div>Condition :</div>
                          <div className="mt-2">Negotiable</div>
                        </div>

                        <div className=" f-16 fw-400 c-gray">
                          <div>{productDetails.condition}</div>
                          <div className="mt-2">
                            {productDetails.isNegotiable == true ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="boder-saidbar-pd mt-2"></div>

                    <div
                      style={{ color: "#464D61" }}
                      className="padd-said-15 cursor"
                    >
                      <span>
                        <GoAlert size={23} />
                      </span>{" "}
                      <span className="f-16 fw-600 ms-2 mt-1">
                        Report Abuse
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container mt-4">
              <div className="f-32 fw-600">Related Ads</div>
              <div className="row mt-4">
                {relativeProducts?.map((product, index) => {
                  return (
                    <div className="col-md-3 mb-4" key={index}>
                      <ProductItem product={product} />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      )}

      {/* model user not login */}
      <div className="seller-model">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title></Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <LoginFormComponent callback={handelCallBack} />
          </Modal.Body>
        </Modal>
      </div>
    </Default>
  );
};

export default ProductDetail;
