import React, { Fragment } from "react";
import UserMiniProfile from "../../../components/user/UserMiniProfile";
import { NavLink } from "react-router-dom";
import { formatter, OrderStatusColor } from "../../../config/common";
import moment from "moment";
import { TbAlertTriangle } from "react-icons/tb";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";

const OrdersItems = (props) => {
  const order = props.orderItem;
  const { type = "Order" } = props;

  const daysRemaining = moment(order.rentEndDate).diff(moment(), "days");

  return (
    <tr key={order.id}>
      <td className="th-title-50" style={{ width: "40%" }}>
        <div className="d-flex rel">
          <NavLink
            to={`/ads/${order.ad.slug}/${order?.ad._id}`}
            state={order?.ad}
          >
            <img
              className="product-image cursor"
              src={order?.ad?.mainImage}
              alt="image"
              width="50"
            />
          </NavLink>
          {order.orderType == "Rent" && (
            <div className="bg-type">{order.orderType}</div>
          )}
          <div className="ms-3">
            <div className="fw-600 mobile-font-16 mobile-table-one-line">
              {order?.ad.title}
            </div>

            {type == "ReceivedOrder" ? (
              <UserMiniProfile user={order?.buyer} />
            ) : (
              <UserMiniProfile user={order?.seller} />
            )}
          </div>
        </div>
      </td>

      <td
        className="c-gray f-13 mobile-table-date-order"
        style={{ width: "20%" }}
      >
        <span className="mt-2">
          {order.orderType == "Sell" ? (
            <Fragment>
              {moment(order.createdAt).format("MMMM DD, YYYY, h:mm a")}{" "}
            </Fragment>
          ) : (
            <div className={``}>
              {moment(order.rentStartDate).format("MMM DD, YYYY")}
              <span className="c-black fw-600"> To </span>
              {moment(order.rentEndDate).format("MMM DD, YYYY")}
              {order.status == "Running" && (
                <div className={`f-13 ${daysRemaining <= 0 ? "c-red" : ""}`}>
                  {Math.abs(daysRemaining)} Days{" "}
                  {daysRemaining < 0 ? <span>Late</span> : <span>Remain</span>}
                </div>
              )}
            </div>
          )}
        </span>
      </td>
      <td className="c-gray-dark  fw-600 mobile-table-w">
        {formatter.format(order.totalAmount)}{" "}
      </td>
      <td
        className={` mobile-font-15 mobile-table-w-status text-one-line ${OrderStatusColor(
          order.status
        )}`}
      >
        {order.isOrderReported && (
          <span className="me-1">
            <TbAlertTriangle
              className="cursor"
              data-tooltip-id="danger"
              size={20}
              color="red"
            />

            <ReactTooltip
              id="danger"
              place="left"
              // place="top"
              content="This order has been reported and is under review by our team. We are investigating the issue to ensure a fair resolution. During this process, payment may be on hold as we gather the necessary information. You will be notified once our review is complete, with details on any next steps. We understand this might be inconvenient, and we appreciate your patience. For updates, please check your order details or contact support for assistance. Rest assured, we are working to resolve the matter as quickly as possible"
              style={{
                maxWidth: "600px",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            />
          </span>
        )}
        {order.status}
      </td>
      <td>
        <NavLink to={`/user/orders/details/${order.orderNumber}`}>
          <button className="btn-orange-outline w-100 f-15 mobile-table-w">
            View
          </button>
        </NavLink>
      </td>
    </tr>
  );
};

export default OrdersItems;
