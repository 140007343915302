import React, { useState, useEffect } from "react";
import Banner from "../../components/header/Banner";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
import Default from "../../components/default/Default";

const Verification = () => {
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // Timer starts from 60 seconds

  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResendDisabled(false);
      setTimer(60); // Reset timer
    }

    return () => clearInterval(interval);
  }, [isResendDisabled, timer]);

  const handelVerificationOtp = async (event) => {
    try {
      event.preventDefault();

      const data = {
        otp: otp,
        token: location.state,
      };

      const options = {
        showLoader: "otp-button",
      };
      const response = await PostRequest(
        "/user/email/verification",
        data,
        options
      );

      showToaster("Your registration has been completed");
      navigate("/home");

      window.localStorage.setItem("auth", JSON.stringify(response));
      window.localStorage.setItem("TOKEN", response.token);
    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
    }
  };

  const handelResendOTP = async () => {
    try {
      setIsResendDisabled(true);

      const data = {
        token: location.state,
      };

      const response = await PostRequest("/send-otp", data);

      // console.log("response", response);

      showToaster("OTP sent to your email");
    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
      setIsResendDisabled(false); // Re-enable the button in case of error
      setTimer(60); // Reset timer in case of error
    }
  };

  return (
    <Default>
      <section>
        <Banner title="Email Verification" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-in-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <form onSubmit={handelVerificationOtp}>
                  <div className="login-bg">
                    <div className="t-center fw-600 mt-3">
                      <h3>Email Verification</h3>
                      <div className="fw-600 mt-4 f-16 c-gray">
                        Enter the 4 digit code we sent to <br /> Email to
                        continue
                      </div>
                    </div>

                    <div className="mt-5 w-100">
                      <OtpInput
                        inputStyle={{
                          height: "60px",
                          width: "100%",
                          border: "1px solid #F8A200",
                          outline: "none",
                          borderRadius: "5px",
                          marginRight: "20px",
                          marginLeft: "10px",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <button
                        id="otp-button"
                        className="my-button padd-btn-login w-100 mt-5 cursor"
                        type="submit"
                      >
                        Verify OTP
                      </button>
                    </div>

                    <div className="gap-30 t-center f-16 fw-400">
                      Didn't get the code?
                      <span
                        onClick={!isResendDisabled ? handelResendOTP : null}
                        className={`c-orange cursor ms-2 ${
                          isResendDisabled ? "disabled" : ""
                        }`}
                        style={{ color: isResendDisabled ? "grey" : "orange" }}
                      >
                        {isResendDisabled
                          ? `Resend in ${timer}s`
                          : "Resend code"}
                      </span>
                    </div>

                    <div className="gap-30 t-center f-16 fw-600 mobile-mt-2">
                      Already have an account?{" "}
                      <NavLink to="/login">
                        <span className="c-orange cursor"> Sign In</span>
                      </NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default Verification;
