import React, { Fragment, useEffect, useRef, useState } from "react";

import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { FaHandsHelping, FaStar } from "react-icons/fa";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { MdDateRange, MdOutlineKeyboardBackspace } from "react-icons/md";
import moment from "moment";
import { PiCurrencyDollarSimpleFill } from "react-icons/pi";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import PostRequest from "../../../services/PostRequest";
import {
  formatter,
  getAuthUser,
  isMobile,
  showToaster,
  toSlug,
} from "../../../config/common";
import { UserOrderDetailsLoader } from "../../../components/loader/AdsItemLoader";
import { GiCheckMark } from "react-icons/gi";

import UserAvatar from "../../../components/user/UserAvatar";
import LottieLoader from "../../../components/lottieloader/LottieLoader";

import { Button, Modal } from "react-bootstrap";
import "./orders-details.css";
import { GoAlert, GoTrash } from "react-icons/go";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import QrMediaUpload from "../../order/orderPhotos/QrMediaUpload";
import { SlideshowLightbox } from "lightbox.js-react";
import { BASE_URL } from "../../../config/Api_Url";

const orderActivitiesPhotos = (data, status) => {
  var photos = data.find((d) => d.type == status)?.photos || [];

  return photos;
};

const OrdersDetails = () => {
  const loginUser = getAuthUser();
  const params = useParams();
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const [loader, setLoader] = useState(false);

  const [orderDetails, setOrderDetails] = useState(false);

  const [orderActivityPhotos, setOrderActivityPhotos] = useState([]);

  // console.log("orderDetails", orderDetails);

  const [show, setShow] = useState(false);

  const [selectReason, setSelectReason] = useState("");
  const [otherMessage, setOtherMessage] = useState("");

  const [reportOrderItem, setReportOrderItem] = useState(false);

  const [isReport, setIsReport] = useState(false);

  // console.log("isReport", isReport);

  const reportClose = () => setReportOrderItem(false);
  const reportShow = () => setReportOrderItem(true);

  // console.log("selectReason", selectReason);
  // console.log("otherMessage", otherMessage);

  const [multipleFile, setMultipleFile] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log("orderDetails", orderDetails);

  const handelOrderPage = () => {
    navigate("/user/orders");
  };

  const loadOrderDetail = async (showLoader = true) => {
    try {
      const data = {
        orderNumber: params.orderNumber,
      };
      if (showLoader) setLoader(true);
      const response = await PostRequest("/user/order/details", data);
      setOrderDetails(response);
      // console.log("response", response);
    } catch (error) {
      console.log("error", error);
    } finally {
      if (showLoader) setLoader(false);
    }
  };

  const handelAcceptOrder = async () => {
    try {
      const data = {
        order: orderDetails?._id,
      };

      if (!window.confirm("Are you sure you want to Accept this Order?"))
        return false;

      const options = {
        showLoader: "btn-accept",
      };

      await PostRequest("/order/accept", data, options);

      loadOrderDetail();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelItemSendBuyer = async () => {
    try {
      const data = {
        order: orderDetails._id,
        // orderType: orderDetails.orderType,
      };

      if (!window.confirm("Are you sure you want to Change Status?"))
        return false;

      const options = {
        showLoader: "btn-send-buyer",
      };

      await PostRequest("/order/send-to-buyer", data, options);
      loadOrderDetail();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelItemReceived = async () => {
    try {
      const data = {
        order: orderDetails._id,
      };
      if (!window.confirm("Are you sure you want to Mark as Item Received?"))
        return false;

      const options = {
        showLoader: "btn-received",
      };

      const response = await PostRequest("/order/received", data, options);

      loadOrderDetail();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelReturnToSeller = async () => {
    try {
      const data = {
        order: orderDetails._id,
      };
      if (!window.confirm("Are you sure you want to Mark as Return To Seller?"))
        return false;

      const options = {
        showLoader: "btn-received",
      };

      await PostRequest("/order/send-to-seller", data, options);

      loadOrderDetail();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handleCompleteOrder = async () => {
    try {
      const data = {
        order: orderDetails._id,
      };
      if (!window.confirm("Are you sure you want to Mark as Received Item?"))
        return false;

      const options = {
        showLoader: "btn-received",
      };

      await PostRequest("/order/received-back", data, options);

      loadOrderDetail();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelSelectReason = (event) => {
    setSelectReason(event.target.value);
  };

  const handelOthermessage = (event) => {
    setOtherMessage(event.target.value);
  };

  const updateFilesMultiple = (incomingFiles) => {
    setMultipleFile(incomingFiles);
  };

  const onDeleteMultiple = (id) => {
    setMultipleFile(multipleFile.filter((x) => x.id !== id));
  };

  const handelOrderCancel = async () => {
    try {
      if (!window.confirm("Are you sure you want to cancel this order?"))
        return false;

      if (!selectReason) {
        setSelectReason("Change of mind");
      }

      const data = {
        orderCancelReason:
          selectReason == "Other" ? otherMessage : selectReason,
        orderNumber: params.orderNumber,
      };

      const options = {
        showLoader: "order-cancel",
      };

      const response = await PostRequest("/order/cancel", data, options);

      showToaster(response);

      setShow(false);
      // console.log("response", response);

      loadOrderDetail();
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handelReportOrderItem = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      if (multipleFile.length == 0) {
        showToaster("Please upload minimum 1 photo.", "error");
        return false;
      }
      multipleFile.map((file) => {
        if (file.errors.length == 0) {
          formData.append("imagesOrVideo", file.file);
        }
      });

      formData.append("order", orderDetails._id);
      const options = {
        showLoader: "report-order",
        isMultiMedia: true,
      };

      const response = await PostRequest("/order/report", formData, options);

      showToaster(response);
      reportClose(false);

      loadOrderReport();
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const loadActivityPhotos = async () => {
    try {
      const data = {
        order: orderDetails._id,
      };
      const response = await PostRequest("/user/order/activity/photos", data);
      setOrderActivityPhotos(response);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const loadOrderReport = async () => {
    try {
      const data = {
        order: orderDetails._id,
      };

      const response = await PostRequest("/order/report/get", data);

      setIsReport(response);
    } catch (error) {
      // console.log("order", error);
    }
  };

  const handelReportDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to Delete Report?"))
        return false;

      const data = {
        order: orderDetails._id,
      };

      const response = await PostRequest("/order/report/delete", data);

      // console.log("response", response);

      showToaster(response);
      setIsReport(false);
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  useEffect(() => {
    loadOrderDetail();
    window.scrollTo(0, 0);
  }, [params.orderNumber]);

  useEffect(() => {
    if (orderDetails) {
      loadActivityPhotos();
      loadOrderReport();
    }
  }, [orderDetails]);

  useEffect(() => {
    // Start the interval when modal opens
    timerRef.current = setInterval(() => {
      loadOrderDetail(false);
    }, 10000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clear interval on component unmount
      }
    };
  }, []);

  if (!orderDetails) {
    return <LottieLoader />;
  }

  const getTimelineButton = (index) => {
    if (["Settlement", "Dispute"].includes(orderDetails.status)) {
      return null;
    }

    return (
      <div>
        {orderDetails.status == "Pending" &&
          orderDetails?.buyer._id == loginUser._id &&
          index == 0 && (
            <button
              // id="order-cancel"
              onClick={handleShow}
              className={`btn-timeline-active mt-3`}
            >
              Cancel Order
            </button>
          )}

        {orderDetails.status == "Pending" &&
          orderDetails.seller._id == loginUser._id &&
          index == 0 && (
            <Fragment>
              <div
                className={`d-flex justify-content-between align-items-center ${
                  isMobile ? "mt-3" : ""
                }`}
              >
                <button
                  id="btn-accept"
                  onClick={handelAcceptOrder}
                  className={`btn-timeline-active `}
                >
                  Accept Order
                </button>

                <button
                  id="order-cancel"
                  onClick={handleShow}
                  className="btn-timeline-active"
                >
                  Denied Order
                </button>
              </div>
            </Fragment>
          )}

        {orderDetails.status == "Accepted" &&
          orderDetails.seller._id == loginUser._id &&
          index == 1 && (
            <Fragment>
              <button
                id="btn-send-buyer"
                onClick={handelItemSendBuyer}
                className="btn-timeline-active mt-2"
              >
                {orderDetails?.orderType == "Rent"
                  ? "Item given to Buyer"
                  : "Item sent to Buyer "}
              </button>

              <QrMediaUpload orderDetails={orderDetails} />
            </Fragment>
          )}

        {orderDetails.status == "Sent to Buyer" &&
          orderDetails?.buyer._id == loginUser._id &&
          index == 2 && (
            <Fragment>
              <button
                id="btn-received"
                onClick={handelItemReceived}
                className="btn-timeline-active mt-2"
              >
                Item Received
              </button>

              <QrMediaUpload orderDetails={orderDetails} />
            </Fragment>
          )}

        {orderDetails.status == "Running" &&
          orderDetails?.buyer._id == loginUser._id &&
          index == 3 && (
            <Fragment>
              <button
                id="btn-received"
                onClick={handelReturnToSeller}
                className="btn-timeline-active mt-2"
              >
                Returned Item To Seller
              </button>

              <div style={{ marginLeft: "-9px" }} className="mt-2">
                <QrMediaUpload orderDetails={orderDetails} />
              </div>
            </Fragment>
          )}

        {orderDetails.status == "Returned To Seller" &&
          orderDetails.seller._id == loginUser._id &&
          index == 4 && (
            <Fragment>
              <button
                id="btn-send-buyer"
                onClick={handleCompleteOrder}
                className="btn-timeline-active mt-2"
              >
                Item Received
              </button>

              <QrMediaUpload orderDetails={orderDetails} />
            </Fragment>
          )}
      </div>
    );
  };

  const renderTimelineItem = (activity, index) => {
    var activityImages = orderActivitiesPhotos(
      orderActivityPhotos,
      activity.status
    );

    if (
      index == orderDetails?.activities.length - 1 &&
      orderDetails.status !== "Completed"
    ) {
      return (
        <VerticalTimelineElement
          className="vertical-timeline-element--work  none-box font-style"
          contentStyle={{
            background: "#f594492b",
            color: "#F8A200",
            padding: "14px",
          }}
          contentArrowStyle={{
            borderRight: "7px solid  #f594492b",
          }}
          date={moment().format("lll")}
          dateClassName="c-orange ms-2 c-black"
          iconStyle={{ background: "#F8A200", color: "white" }}
          icon={<FaHandsHelping />}
        >
          <div>
            <div className="vertical-timeline-element-title fw-600 f-18">
              {activity?.title}
            </div>

            <div className="mt-1 f-12">{activity.description}</div>
            <div className="mt-1 f-12 fw-600">{activity.message}</div>

            <div className="mt-2">{getTimelineButton(index)}</div>
          </div>
        </VerticalTimelineElement>
      );
    } else {
      return (
        <VerticalTimelineElement
          key={index}
          className={`vertical-timeline-element--work font-style none-vertical-timeline-element-content`}
          contentStyle={{
            background: `#F8A200`,
            color: "white",
            padding: "14px",
          }}
          contentArrowStyle={{
            borderRight: "7px solid  #F8A200",
          }}
          date={moment(activity.date).format("lll")}
          dateClassName="c-orange ms-2 c-black"
          iconStyle={{
            background: "#F8A200",
            color: "white",
            fontSize: "30px",
          }}
          icon={<GiCheckMark />}
        >
          <div>
            <div className={`vertical-timeline-element-title fw-600 f-18`}>
              <div className="f-16">{activity?.title}</div>
              <div className="mt-1 f-12">{activity.description}</div>

              <div>
                {/* <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                  {orderActivitiesPhotos( orderActivityPhotos,activity.status).map((img) => (
                    <img
                      className="m-1 cursor"
                      src={img}
                      height={30}
                      width={30}
                      style={{ borderRadius: 2 }}
                    />
                  ))}
                </SlideshowLightbox> */}

                <SlideshowLightbox
                  key={activityImages.join(",")}
                  className="container grid grid-cols-3 gap-2 mx-auto"
                >
                  {activityImages.map((img) => (
                    <img
                      className="m-1 cursor"
                      src={img}
                      height={30}
                      width={30}
                      style={{ borderRadius: 2 }}
                    />
                  ))}
                </SlideshowLightbox>

                {/* {orderActivitiesPhotos(
                  orderActivityPhotos,
                  activity.status
                ).map((img) => (
                  <img
                    className="m-1 cursor"
                    src={img}
                    height={30}
                    width={30}
                    style={{ borderRadius: 2 }}
                  />
                ))} */}
              </div>
            </div>
          </div>
        </VerticalTimelineElement>
      );
    }
  };

  return (
    <DefaultDashboard title={orderDetails?.ad?.title}>
      {isReport && (
        <div className="row">
          <div
            style={{ overflow: "hidden" }}
            className="alert alert-danger"
            role="alert"
          >
            <h5 className="t-center">
              <b> This order is reported by {isReport?.reportedBy.name}</b>
            </h5>
            <div className="mt-3 overflow-hidden">{isReport?.message}</div>
            {isReport?.imagesOrVideo.length != 0 && (
              <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                {isReport?.imagesOrVideo.map((image, index) => {
                  // console.log("image", image);
                  return (
                    <img
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                      key={index}
                      className="me-3 mt-3"
                      height={60}
                      width={60}
                      src={BASE_URL + image}
                      alt="image"
                    />
                  );
                })}
              </SlideshowLightbox>
            )}
            <div className="fw-600 mt-3 f-18">Decision from rentoho</div>
            <div
              dangerouslySetInnerHTML={{ __html: isReport?.decision }}
              className="mt-2"
            ></div>
          </div>
        </div>
      )}

      {loader ? (
        <Fragment>
          <UserOrderDetailsLoader />
        </Fragment>
      ) : (
        <Fragment>
          <div className="row">
            <div className="col-md-8">
              <div className="bg-user-post-ad p-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div
                    onClick={handelOrderPage}
                    className="fw-700 f-18 c-orange"
                  >
                    <MdOutlineKeyboardBackspace size={27} /> Order Activity
                  </div>

                  {!isReport && (
                    <div onClick={reportShow} className="cursor text-danger">
                      <span>
                        <GoAlert size={23} />
                      </span>{" "}
                      <span className="f-16 fw-600 ms-1 mt-1">
                        Report this order
                      </span>
                    </div>
                  )}

                  {isReport &&
                    isReport.reportedBy._id == loginUser._id &&
                    isReport.status == "New" && (
                      <div
                        onClick={handelReportDelete}
                        className="cursor text-danger"
                      >
                        <span>
                          <GoTrash size={23} />
                        </span>{" "}
                        <span className="f-16 fw-600 ms-1 mt-1">
                          Delete Report
                        </span>
                      </div>
                    )}
                </div>

                <VerticalTimeline lineColor="#F8A200">
                  {orderDetails?.activities.map((activity, index) => {
                    return renderTimelineItem(activity, index);
                  })}

                  {/**/}
                </VerticalTimeline>
              </div>
            </div>
            <div className="col-md-4 mobile-mt-3">
              {loginUser?._id == orderDetails?.seller?._id ? (
                <div className="order-detail-card f-16 c-black fw-600">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="f-18">Buyer Details</div>
                    <div>
                      <NavLink to={`/user/messages/${orderDetails?.buyer._id}`}>
                        <button className="btn-orange-outline rounded-pill f-13 mobile-btn-sendMessage">
                          <IoChatbubbleEllipsesOutline size={22} /> &nbsp;Send
                          Message
                        </button>
                      </NavLink>
                    </div>
                  </div>

                  <NavLink
                    to={`/seller/${toSlug(orderDetails?.buyer?.name)}/${
                      orderDetails?.buyer?._id
                    }/profile`}
                    className={"c-black"}
                  >
                    <div className="d-flex align-items-center  gap-20">
                      <UserAvatar
                        size="50"
                        url={orderDetails?.buyer.profileImage}
                        name={orderDetails?.buyer?.name}
                      />
                      <div className="fw-600 ms-3">
                        {orderDetails?.buyer?.name}
                        <div className="d-flex align-items-center">
                          <FaStar className="c-orange" />
                          <div className="mt-1">
                            <span className="ms-1">
                              {!orderDetails?.buyer._id.averageRating ? (
                                "0"
                              ) : (
                                <Fragment>
                                  {orderDetails?.buyer?.averageRating}
                                </Fragment>
                              )}
                            </span>
                            <span
                              style={{ color: "gray" }}
                              className="c-gray-dark"
                            >
                              ({orderDetails?.buyer?.totalRating})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className="order-detail-card f-16 c-black fw-600">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="f-18">Seller Details</div>
                    <div>
                      <NavLink
                        to={`/user/messages/${orderDetails?.seller._id}`}
                      >
                        <button className="btn-orange-outline rounded-pill f-13 mobile-btn-sendMessage">
                          <IoChatbubbleEllipsesOutline size={22} /> &nbsp;Send
                          Message
                        </button>
                      </NavLink>
                    </div>
                  </div>

                  <NavLink
                    to={`/seller/${toSlug(orderDetails.seller.name)}/${
                      orderDetails.seller._id
                    }/profile`}
                    className={"c-black"}
                  >
                    <div className="d-flex align-items-center  gap-20">
                      <UserAvatar
                        size="50"
                        url={orderDetails?.seller.profileImage}
                        name={orderDetails?.seller?.name}
                      />
                      <div className="fw-600 ms-3">
                        {orderDetails?.seller?.name}
                        <div className="d-flex align-items-center">
                          <FaStar className="c-orange" />
                          <div className="mt-1">
                            <span className="ms-1">
                              {!orderDetails.seller.averageRating ? (
                                "0"
                              ) : (
                                <Fragment>
                                  {orderDetails?.seller?.averageRating}
                                </Fragment>
                              )}
                            </span>
                            <span
                              style={{ color: "gray" }}
                              className="c-gray-dark"
                            >
                              ({orderDetails?.seller?.totalRating})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              )}

              <div className="order-detail-card f-16 c-black fw-600 mt-3 mobile-mt-2">
                <div className="f-18">Payment Details</div>
                <div className="d-flex justify-content-between align-items-center mt-2 f-13 c-gray">
                  <div>Order Amount</div>
                  <div>
                    {formatter.format(
                      orderDetails.totalAmount + orderDetails.discountAmount
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2 f-13 c-gray">
                  <div>Discount</div>
                  <div>{formatter.format(orderDetails.discountAmount)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2 f-13 c-gray">
                  <div>Total Amount</div>
                  <div>{formatter.format(orderDetails.totalAmount)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2 f-13 c-gray">
                  <div>Pay Id</div>
                  <div>{orderDetails.paymentId}</div>
                </div>
              </div>

              <div className="order-detail-card f-16 c-black fw-600 mt-3 mobile-mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="f-18">Order Details</div>
                </div>

                <div className="mt-3 fw-600">
                  <MdDateRange size={20} className="c-orange" />{" "}
                  <span className="ms-1">
                    {orderDetails.orderType == "Sell" ? (
                      <Fragment>
                        {moment(orderDetails.createdAt).format("MMMM DD, YYYY")}{" "}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {moment(orderDetails.rentStartDate).format(
                          "MMM DD, YYYY"
                        )}{" "}
                        <span className="c-black fw-600">-</span>{" "}
                        {moment(orderDetails.rentEndDate).format(
                          "MMM DD, YYYY"
                        )}
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="mt-2 fw-600">
                  <PiCurrencyDollarSimpleFill className="c-orange" size={20} />{" "}
                  <span className="ms-1">
                    {formatter.format(orderDetails?.ad?.price)}{" "}
                    {orderDetails.orderType == "Rent" && (
                      <span className="c-gray f-13">
                        {" "}
                        / {orderDetails?.ad?.priceType}
                      </span>
                    )}
                  </span>
                </div>

                <div className="mt-3">
                  <img
                    className="border-radius-10"
                    width="100%"
                    src={orderDetails?.ad?.mainImage}
                    alt="image"
                  />

                  <div className="fw-700 f-16 mt-2 ">
                    {orderDetails?.ad?.title}
                  </div>
                  <div className="mt-2 f-13 c-gray">
                    {orderDetails?.ad?.description}
                  </div>

                  <NavLink
                    to={`/ads/${orderDetails.ad?.slug}/${orderDetails.ad?._id}`}
                    state={orderDetails.ad}
                  >
                    <div className="f-14 mt-2 fw-600 c-orange">View More</div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {/* Cancel Order Model  */}

      <Modal show={show} onHide={handleClose} className="model-title-block">
        <Modal.Header closeButton>
          <Modal.Title className="c-orange fw-700">Cancel Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fw-600 f-17 mt-4">Select Cancel Reason</div>
          <select
            required
            onChange={handelSelectReason}
            className="my-input mt-1"
          >
            {/* <option value="">Select Reason....</option> */}

            <option value="Change of mind">Change of mind</option>

            <optgroup label="Customer Requested Cancellation">
              <option value="Change of mind">Change of mind</option>
              <option value="Found a better price elsewhere">
                Found a better price elsewhere
              </option>
              <option value="No longer needed">No longer needed</option>
              <option value="Incorrect product ordered">
                Incorrect product ordered
              </option>
            </optgroup>

            <optgroup label="Payment Issues">
              <option value="Payment declined">Payment declined</option>
              <option value="Fraud detection">Fraud detection</option>
            </optgroup>

            <optgroup label="Inventory or Supply Issues">
              <option value="Out of stock">Out of stock</option>
              <option value="Supplier delay">Supplier delay</option>
              <option value="Discontinued product">Discontinued product</option>
            </optgroup>

            <optgroup label="Shipping Issues">
              <option value="Delivery delay">Delivery delay</option>
              <option value="Incorrect shipping address">
                Incorrect shipping address
              </option>
              <option value="High shipping cost">High shipping cost</option>
            </optgroup>

            <optgroup label="Product Issues">
              <option value="Incorrect item listed">
                Incorrect item listed
              </option>
              <option value="Item not as described">
                Item not as described
              </option>
              <option value="Quality concerns">Quality concerns</option>
            </optgroup>

            <optgroup label="Business/Store Issues">
              <option value="Store closing">Store closing</option>
              <option value="Policy changes">Policy changes</option>
              <option value="Operational issues">Operational issues</option>
            </optgroup>

            <optgroup label="External Factors">
              <option value="Weather-related delays">
                Weather-related delays
              </option>
              <option value="Natural disasters">Natural disasters</option>
              <option value="Global events (e.g., pandemics)">
                Global events (e.g., pandemics)
              </option>
            </optgroup>

            <optgroup label="Technical Issues">
              <option value="Website error">Website error</option>
              <option value="System glitch">System glitch</option>
            </optgroup>

            <option value="Other">Other</option>
          </select>

          {selectReason == "Other" && (
            <textarea
              className="text-area-input cursor w-100 mt-2"
              onChange={handelOthermessage}
              maxLength={200}
              placeholder="Enter Reason"
              rows="3"
              value={otherMessage}
              required
            ></textarea>
          )}

          <div className="t-end mt-3">
            <button
              onClick={handelOrderCancel}
              id="order-cancel"
              className="coman-btn"
            >
              Cancel Order
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* report order item */}
      <Modal show={reportOrderItem} onHide={reportClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Report this order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handelReportOrderItem}>
            <div className="fw-600 f-16">Title</div>
            <input
              className="my-input mt-1"
              type="text"
              placeholder="Title"
              required
              name="title"
            />

            <div className="fw-600 f-16 mt-3">Messages</div>
            <textarea
              className="text-area-input cursor w-100 mt-1"
              // onChange={(e) => setReportMessage(e.target.value)}
              maxLength={200}
              placeholder="write your message..."
              rows="3"
              name="message"
              required
              minLength={100}
            ></textarea>

            <div className="mt-3">
              <div className="f-16 fw-600 mt-1">Upload Photos Or Video</div>
              <Dropzone
                style={{ height: "100px" }}
                onChange={updateFilesMultiple}
                minHeight="195px"
                value={multipleFile}
                maxFiles={6}
                maxFileSize={25000000}
                accept=".png,image/*"
                url="https://my-awsome-server/upload-my-file"
                fakeUploading
                disableScroll
              >
                {multipleFile.length > 0 &&
                  multipleFile.map((file) => (
                    <FileItem
                      {...file}
                      key={file.id}
                      onDelete={onDeleteMultiple}
                      preview
                      hd
                    />
                  ))}
              </Dropzone>
            </div>

            <div className="t-end mt-3">
              <button
                id="report-order"
                className="coman-btn"
                // onClick={reportClose}
              >
                Report this order
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </DefaultDashboard>
  );
};

export default OrdersDetails;
