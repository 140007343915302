import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import PostRequest from "../../../services/PostRequest";
import { showToaster } from "../../../config/common";
// import { Country } from "country-state-city";
import { useParams } from "react-router-dom";

const PaymentsDetails = () => {
  const [paymentSetting, setPaymentSetting] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Paypal Email");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);

  const { account } = useParams();

  //
  const handelPaypalAccount = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });

      data.defaultPaymentMethod = paymentMethod;
      // data.activeAccountType = activeAccountType;
      // data.country = activeCountry;

      // console.log("data", data);

      const options = {
        showLoader: "paypal-id",
      };

      const response = await PostRequest(
        "/user/payment-setting/update",
        data,
        options
      );

      showToaster(response);
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const loadPaymentSettings = async () => {
    try {
      const response = await PostRequest("/user/payment-setting");

      setPaymentMethod(response.defaultPaymentMethod);
      setPaymentSetting(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleOnboarding = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const response = await PostRequest("/user/stripe/create-account");
      const { url } = response;
      window.location.href = url;
    } catch (error) {
      console.log(error.message);
    }
  };

  const verifyStripeAccount = async () => {
    try {
      const response = await PostRequest("/user/stripe/verify-account", {
        account: account,
      });
      loadPaymentSettings();
      // console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDisconnect = async () => {
    if (isLoadingDisconnect) return;
    try {
      setIsLoadingDisconnect(true);
      const response = await PostRequest("/user/stripe/disconnect-account", {
        account: account,
      });
      loadPaymentSettings();
      // console.log("response", response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoadingDisconnect(false);
    }
  };

  const handleChangePaymentMethod = async () => {
    try {
      const options = {
        showLoader: "paypal-id-method",
      };

      const data = {
        // paypalEmail: paymentMethod.paypalEmail,
        defaultPaymentMethod: paymentMethod,
      };

      const response = await PostRequest(
        "/user/payment-setting/update",
        data,
        options
      );

      showToaster(response);
    } catch (error) {
      showToaster(error.message, "error");
    }
  };
  //
  const renderBankConnectionStatus = () => {
    if (paymentSetting?.stripeAccountStatus == "Completed") {
      return (
        <span>
          {" "}
          <label
            className="bg bg-success"
            style={{ height: 10, width: 10, borderRadius: 10 }}
          ></label>{" "}
          <span className="text text-success f-13"> Connected </span>{" "}
        </span>
      );
    }

    return (
      <span>
        {" "}
        <label
          className="bg bg-info"
          style={{ height: 10, width: 10, borderRadius: 10 }}
        ></label>{" "}
        <span className="text text-info f-13"> Pending </span>{" "}
      </span>
    );
  };

  useEffect(() => {
    loadPaymentSettings();
  }, []);

  useEffect(() => {
    if (!account) return;

    verifyStripeAccount();
  }, [account]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultDashboard title="Payment Settings">
      <div className="bg-user-post-ad p-20">
        <span className="f-20 fw-600 mobile-font-20">Payment Settings</span>

        <div className="mt-4">
          <form onSubmit={handelPaypalAccount}>
            <div>
              <input
                id="paypal-email"
                onChange={(e) => setPaymentMethod("Paypal Email")}
                type="radio"
                checked={paymentMethod === "Paypal Email"}
                className="accent-color"
              />

              <label
                htmlFor="paypal-email"
                className={`ms-2 ${
                  paymentMethod === "Paypal Email" ? "c-orange fw-700" : ""
                }`}
              >
                Paypal Details
              </label>
            </div>

            {paymentMethod == "Paypal Email" && (
              <div className="mt-2 ms-4">
                <small className="c-gray">
                  Ensure your PayPal details are up-to-date for a smooth
                  transaction.
                </small>
                <div className="fw-600 f-16 mt-1">Paypal Email</div>
                <div className="mt-1 input-login cursor">
                  <input
                    required
                    name="paypalEmail"
                    className="input-sigin-1"
                    type="email"
                    placeholder="Enter Paypal Email"
                    defaultValue={paymentSetting?.paypalEmail}
                  />
                </div>

                <div className="mt-4 t-end">
                  <button type="submit" id="paypal-id" className="coman-btn">
                    Save Change
                  </button>
                </div>
              </div>
            )}

            <div className="mt-2">
              <input
                onChange={(e) => setPaymentMethod("Stripe Account")}
                type="radio"
                id="bank-id"
                className="accent-color"
                checked={paymentMethod === "Stripe Account"}
              />

              <label
                htmlFor="bank-id"
                className={`ms-2 ${
                  paymentMethod === "Stripe Account" ? "c-orange fw-700" : ""
                }`}
              >
                Bank Transfer {renderBankConnectionStatus()}
              </label>
            </div>
          </form>

          {paymentMethod == "Stripe Account" && (
            <div className="mt-2 ms-4">
              <small className="c-gray">
                Transfer funds directly from your bank account.
              </small>

              {
                <div>
                  {paymentSetting.stripeAccountStatus != "Completed" && (
                    <small className="c-gray">
                      When you click on following Connect Stripe Button, it will
                      redirect you to stripe onboarding process, please fill all
                      the required fields and once the process done, you will
                      automatically redirect back to rentoho app.
                    </small>
                  )}
                </div>
              }

              {paymentSetting.stripeAccountStatus == "Completed" && (
                <div>
                  <div className="mt-1 mobile-mt-2">
                    <span className="fw-600 f-16">Account Holder Name</span>

                    <div className="mt-1 input-login cursor">
                      <input
                        disabled
                        className="input-sigin-1"
                        type="text"
                        value={paymentSetting?.accountDetails?.name}
                      />
                    </div>
                  </div>

                  <div className="fw-600 f-16 mt-3">Stripe Account Number</div>
                  <div className="mt-1 input-login cursor">
                    <input
                      disabled
                      className="input-sigin-1"
                      value={paymentSetting?.stripeAccount}
                    />
                  </div>
                </div>
              )}

              <div className="mt-4">
                {paymentSetting?.stripeAccountStatus == "Completed" ? (
                  <div className="d-flex justify-content-between">
                    <button
                      disabled={isLoadingDisconnect}
                      onClick={!isLoadingDisconnect ? handleDisconnect : null}
                      id="paypal-id"
                      className="btn btn-danger"
                    >
                      {isLoading && (
                        <label class="spinner-border spinner-border-sm me-1" />
                      )}
                      <span>Disconnect Stripe</span>
                    </button>

                    <button
                      onClick={handleChangePaymentMethod}
                      id="paypal-id-method"
                      className="coman-btn"
                    >
                      Make Default
                    </button>
                  </div>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={!isLoading ? handleOnboarding : null}
                    id="paypal-id"
                    className="coman-btn"
                  >
                    {isLoading && (
                      <label class="spinner-border spinner-border-sm me-1" />
                    )}
                    <span>Connect Stripe</span>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default PaymentsDetails;
