import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
// import PostRequest from "../../../services/PostRequest";
// import { useState } from "react";

// This value is from the props in the UI
const style = { layout: "vertical" };

const ButtonWrapper = ({ showSpinner, onApprove, createOrder }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </>
  );
};

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const PaypalPaymentMethod = ({ onApprove, createOrder }) => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: clientId,
        components: "buttons",
        currency: "CAD",
      }}
    >
      <ButtonWrapper
        onApprove={onApprove}
        createOrder={createOrder}
        showSpinner={false}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalPaymentMethod;
