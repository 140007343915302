import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { LuEye } from "react-icons/lu";
import * as Yup from "yup";
import "./profile-verification.css";
import { FaRegEyeSlash } from "react-icons/fa";
import QRCode from "react-qr-code";
import DefaultDashboard from "../../dashboard/DefaultDashboard";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { QRCodeGenerator, showToaster } from "../../../config/common";
import PostRequest from "../../../services/PostRequest";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie";

// Validation Schema
const validationSchema = Yup.object().shape({});

// Initial Values
const adsInformationInitialValue = {
  nationalIdentificationNumber: "",
};

const ProfileVerification = (props) => {
  // console.log("props", props);
  const documentStatus = window.localStorage.getItem("DocumentStatus");

  // console.log("documentStatus", documentStatus);

  const [activeStep, setActiveStep] = useState("Personal Information");

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [showNationality, setShowNationality] = useState(false);

  const [qrToken, setQrToken] = useState("");

  const handelPersonalInfo = async (values) => {
    try {
      if (!selectedDate) {
        showToaster("Please select your birth date", "error");
        return false;
      }

      if (moment().diff(selectedDate, "years") < 18) {
        showToaster("age should be minimum 18 years", "error");
        return false;
      }

      const options = {
        showLoader: "load-submit",
      };

      values.dob = moment(selectedDate).format("MM/dd/yyyy");

      const response = await PostRequest(
        "/user/documents/add-update",
        values,
        options
      );

      setActiveStep("Verification");

      setQrToken(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handelChangeActiveTab = (tab) => {
    setActiveStep(tab);
  };

  const handelNationalityShow = () => {
    setShowNationality(!showNationality);
  };

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./documnet-loader.json"),
  };

  return (
    <DefaultDashboard>
      {documentStatus == "Pending" || documentStatus == "In Progress" ? (
        <div className="bg-user-post-ad p-20">
          <div style={{ background: "#fff2de" }} className="lottie-wrapper">
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>

          <p className="mt-2 text-center w-75 m-auto">
            Your documents & proof are under progress, our team will check your
            documents and verified it, you will be get notification via email.
          </p>
        </div>
      ) : (
        <div className="bg-user-post-ad p-20">
          <div className="d-flex justify-content-around bottom-line-tab mobile-overflow-scroll">
            <div
              className={`step-box d-flex align-ietms-center ${
                activeStep == "Personal Information" ? "border-active" : ""
              }`}
            >
              <div className="round-step1 f-32 mobile-round-steps mobile-font-23 mobile-mt-1">
                1
              </div>

              <div className="ms-3 mt-1">
                <div className="f-18 fw-600 mobile-font-16 mobile-fw-700">
                  Steps 01
                </div>
                <div className="f-14 fw-400 c-gray mobile-one-line">
                  Personal Information
                </div>
              </div>
            </div>

            <div
              className={`step-box d-flex align-items-center ${
                activeStep == "Verification" ? "border-active" : ""
              }`}
            >
              <div className="round-step1 f-32 mobile-round-steps mobile-font-23 mobile-mt-1">
                2
              </div>

              <div className="ms-3 mt-1">
                <div className="f-18 fw-600 mobile-font-16 mobile-fw-700">
                  Steps 02
                </div>
                <div className="f-14 fw-400 c-gray mobile-one-line">
                  Verification
                </div>
              </div>
            </div>

            <div
              className={`step-box d-flex align-ietms-center ${
                activeStep == "Upload Documents" ? "border-active" : ""
              }`}
            >
              <div className="round-step1 f-32 mobile-round-steps mobile-font-23 mobile-mt-1">
                3
              </div>

              <div className="ms-3 mt-1">
                <div className="f-18 fw-600 mobile-font-16 mobile-fw-700">
                  Steps 03
                </div>
                <div className="f-14 fw-400 c-gray mobile-font-12 mobile-one-line">
                  Upload Documents
                </div>
              </div>
            </div>
          </div>

          {activeStep == "Personal Information" && (
            <div>
              <Formik
                initialValues={adsInformationInitialValue}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handelPersonalInfo(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  setValues,
                  dirty,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mt-4">
                      <div className="fw-600 f-16">Date of Birth</div>

                      <DatePicker
                        className="w-100 mt-1"
                        value={selectedDate}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        maxDate={eighteenYearsAgo}
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        dateFormat={"MM/dd/yyyy"}
                        showYearDropdown
                        scrollableYearDropdown
                      />

                      <div className="mt-3 rel">
                        <div className="fw-600 f-16">
                          National identify number
                        </div>
                        <input
                          className="my-input mt-1"
                          type={showNationality ? "text" : "password"}
                          placeholder="National identify number"
                          name="nationalIdentificationNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationalIdentificationNumber}
                        />

                        <span
                          onClick={handelNationalityShow}
                          className="eyes-personal-verify"
                        >
                          {!showNationality ? (
                            <LuEye size={19} />
                          ) : (
                            <FaRegEyeSlash size={19} />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="gap-30">
                      <div className="t-end">
                        <button
                          id="load-submit"
                          type="submit"
                          disabled={!isValid || !dirty}
                          className="coman-btn ms-3"
                        >
                          Next Step &nbsp;
                          <FaArrowRightLong />
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}

          {activeStep == "Verification" && (
            <div className="f-18 mt-5">
              <div className="c-black t-center">
                Please verify your identity throgh the electronic know, your
                customer (eKYC) process.
              </div>
              <div className="c-gray fw-400 mt-2 t-center">
                Please prepare your Government Issued Driving License, or
                passport for the veification process. <br />
                <span className="text-underline">
                  How do i complete eKYC verification?
                </span>
              </div>

              <div className="d-center">
                <img height="auto" width="350px" src="/images/face.jpeg" />
              </div>

              <div className="gap-30">
                <div className="t-end">
                  <button
                    onClick={() =>
                      handelChangeActiveTab("Personal Information")
                    }
                    type="button"
                    className="coman-btn-boder ms-3"
                  >
                    Previous
                  </button>

                  <button
                    type="button"
                    onClick={() => handelChangeActiveTab("Upload Documents")}
                    className="coman-btn ms-3"
                  >
                    Verify now
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeStep == "Upload Documents" && (
            <div className="f-18 mt-5">
              <div className="c-black t-center">
                Scan the QR code below using your mobile device
                <div className="c-gray mt-2">
                  {" "}
                  We recommend using the latest version of chrome,firefox,or
                  safari <br />
                  (ios) for this verification process
                </div>
              </div>

              <div className="d-center mt-5">
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 200,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    id="qr"
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={QRCodeGenerator(qrToken)}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>

              <NavLink to={`/user/profile/verify/country/${qrToken}`}>
                <div className="mt-5 cursor d-center  c-gary text-underline">
                  or continue using your web browser
                </div>
              </NavLink>

              <div className="gap-30">
                <div className="t-end">
                  <button
                    onClick={() => handelChangeActiveTab("Verification")}
                    type="button"
                    className="coman-btn-boder ms-3"
                  >
                    Previous
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </DefaultDashboard>
  );
};

export default ProfileVerification;
