import React, { Fragment, useEffect, useState } from "react";
import { PaymentLoader } from "../../../components/loader/AdsItemLoader";
import PaymentsReceivedItems from "./PaymentsReceivedItems";
import { Table } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";
import SecurityDepositItem from "./SecurityDepositItem";

const SecurityDepositComponents = () => {
  const [loader, setLoader] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [securityDepositDetails, setSecurityDepositDetails] = useState([]);

  const loadSecurityDepositPayments = async (
    isLoadMore = false,
    hasExtraPayload
  ) => {
    try {
      if (!isLoadMore) {
        setLoader(true);
      }

      const data = {
        type: "Deposit",
      };

      const activeData = { ...data, ...hasExtraPayload };

      const options = {
        showLoader: "load-more",
      };

      const response = await PostRequest("/user/payments", activeData, options);

      // console.log("response", response);

      if (isLoadMore) {
        setSecurityDepositDetails((prev) => [...prev, ...response]);
      } else {
        setSecurityDepositDetails(response);
      }

      if (response.length < 20) {
        setHasLoadMore(false);
      } else {
        setHasLoadMore(true);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      if (!isLoadMore) {
        setLoader(false);
      }
    }
  };

  const loaderPaymentData = () => {
    const data = {
      skip: securityDepositDetails.length,
    };

    loadSecurityDepositPayments(true, data);
  };

  useEffect(() => {
    loadSecurityDepositPayments();
  }, []);

  return (
    <div className="row mt-4">
      <Table className="user-ads-list-table" hover responsive>
        <thead>
          <tr className="f-13">
            <th className="text-one-line">Date</th>
            <th className="text-one-line text-center">Order</th>
            <th className="text-one-line text-center">Deposit Amount</th>
            <th className="text-one-line text-center">Late Fees Charge</th>
            <th className="text-one-line text-center">Total Amount</th>
            <th className="text-one-line">Status &nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {loader ? (
            <PaymentLoader />
          ) : (
            <Fragment>
              {securityDepositDetails.map((payment, index) => {
                return <SecurityDepositItem key={index} payment={payment} />;
              })}
            </Fragment>
          )}

          {loader ? (
            ""
          ) : (
            <Fragment>
              {securityDepositDetails.length == 0 && (
                <tr className="t-center">
                  <td colSpan={5}>
                    <h5 className="c-gray mt-3">"0" payments.</h5>
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </Table>

      {loader ? (
        ""
      ) : (
        <Fragment>
          {hasLoadMore && securityDepositDetails.length != 0 && (
            <div className="t-center">
              <button
                id="load-more"
                onClick={loaderPaymentData}
                className="my-button fw-600 mt-3 w-25 loadMore-btn cursor"
              >
                Load More
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default SecurityDepositComponents;
