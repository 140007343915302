import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";

import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Validation Schema
const validationSchema = Yup.object().shape({
  rentStartDate: Yup.string().required("Date is required"),
  daysOfRent: Yup.string().required("Days is required"),
});

// Initial Values
const adsInformationInitialValue = {
  rentStartDate: "",
  daysOfRent: "",
};

const OrderOverview = (props) => {
  const { handleOnChangeTab, handleOnSubmitData } = props;
  return (
    <div>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleOnSubmitData(values);
          handleOnChangeTab("Shipping Details");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="fw-600 f-16 mt-3">Rental start date</div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  className="my-input mt-1"
                  type="date"
                  name="rentStartDate"
                  value={values.rentStartDate}
                  min={moment().format("YYYY-MM-DD")}
                />

                {/* <input
                  onChange={(e) => {
                    handleChange();
                    dateFormate();
                  }}
                  onBlur={handleBlur}
                  required
                  className="my-input mt-1"
                  type="date"
                  name="rentStartDate"
                  value={moment(rentStartDate, "YYYY/MM/DD").format(
                    "YYYY-MM-DD"
                  )} // Convert back for the input
                  min={moment().format("YYYY-MM-DD")}
                /> */}

                <div className="fw-600 f-16 mt-3">
                  {" "}
                  Rental duration ( in Days )
                </div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  required
                  name="daysOfRent"
                  className="my-input mt-1"
                  type="text"
                  placeholder="Days"
                  value={values.daysOfRent}
                />
              </div>
            </div>

            <div className="gap-30 t-end">
              <button
                type="button"
                //  onClick={() => handleOnChangeTab(2)}
                className="coman-btn-boder"
              >
                Previous
              </button>

              <button
                id="btn-order1"
                type="submit"
                disabled={!isValid || !dirty}
                className="coman-btn ms-3"
              >
                Next Step &nbsp;
                <FaArrowRightLong />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default OrderOverview;
