import React, { Fragment, useEffect, useState } from "react";

import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { FaArrowRightLong } from "react-icons/fa6";
import { PiSealCheckDuotone } from "react-icons/pi";
import { NavLink } from "react-router-dom";

import PostRequest from "../../../services/PostRequest";
import * as Yup from "yup";

import AdsInformationForm from "../form/AdsInformationForm";
import AdsInformationImages from "../form/AdsInformationImages";
import AdsInfomationAddress from "../form/AdsInfomationAddress";
import { getAuthUser, showToaster } from "../../../config/common";

import "./create-post.css";
import Lock from "../../../components/lock/Lock";
import PromotionPlans from "../ad-promotion-plans/PromotionPlans";

// Validation Schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  price: Yup.string().required("Price is required"),
  category: Yup.string().required("Category is required"),
});

// Initial Values
const adsInformationInitialValue = {
  title: "",
  category: "",
  price: "",
};

const CreateNewPost = () => {
  const loginUser = getAuthUser();

  // console.log('loginUser',loginUser)

  const [activeStep, setActiveStep] = useState(1);
  const [transactionType, setTransactionType] = useState("Sell");

  // const [adsInformationData, setAdsInformationData] = useState("");
  // const [adsImagesData, setAdsImagesData] = useState("");

  const [finalData, setFinalData] = useState({});

  const handleOnChangeTab = (tab) => {
    setActiveStep(tab);
  };

  const handleChangeTransactionType = (tab) => {
    setTransactionType(tab);
  };

  const handleOnSubmitData = (values) => {
    setFinalData({ ...finalData, ...values });
  };

  const renderPostSuccess = () => {
    return (
      <div className="success-bg-white mobile-p-30-20">
        <PiSealCheckDuotone color="#27C200" className="mobile-font-45 f-70" />

        <h2 className="mt-3 mobile-font-22">ad published</h2>

        <div className="f-16 c-gray t-center mt-3">
          Your ad has been posted successfully
        </div>

        <div className="mt-4">
          {/* <NavLink to={"/home"}> */}
          <button
            onClick={() => handleOnChangeTab(1)}
            style={{ border: "1px solid #F8A200", color: "#F8A200" }}
            className="coman-btn-boder"
          >
            Go back
          </button>
          {/* </NavLink> */}
          <NavLink to={"/user/ads"}>
            <button className="coman-btn ms-3">
              View Ads &nbsp;
              <FaArrowRightLong />
            </button>
          </NavLink>
        </div>
      </div>
    );
  };

  const handelOnSubmitPostAdd = async (values) => {
    try {
      values.transactionType = transactionType;
      const options = {
        showLoader: "postUpload-btn",
        isMultiMedia: false,
      };
      await PostRequest("/ad/create", { ...finalData, ...values }, options);

      // console.log('{ ...finalData, ...values}',{ ...finalData, ...values});
      handleOnChangeTab(5);
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <DefaultDashboard title="Post an Ad">
      <div className="rel">
        <div
          className={`bg-user-post-ad ${
            loginUser &&
            (!loginUser.documentVerificationStatus ||
              loginUser.documentVerificationStatus != "Verified")
              ? "blur-bg"
              : ""
          } ${activeStep == 5 ? "hidden-in-mobile hidden-in-desktop" : ""}`}
        >
          {activeStep != 5 && (
            <Fragment>
              <div className="row">
                <div className="col-md-3">
                  <div
                    className={`step-box d-flex align-ietms-center ${
                      activeStep == 1 ? "border-active" : ""
                    }`}
                  >
                    <div className="round-step1 f-32">1</div>

                    <div className="ms-3 mt-1">
                      <div className="f-18 fw-600">Steps 01</div>
                      <div className="f-14 fw-400 c-gray">Ads Information</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div
                    className={`step-box d-flex align-ietms-center ${
                      activeStep == 2 ? "border-active" : ""
                    }`}
                  >
                    <div className="round-step1 f-32">2</div>

                    <div className="ms-3 mt-1">
                      <div className="f-18 fw-600">Steps 02</div>
                      <div className="f-14 fw-400 c-gray">Description</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div
                    className={`step-box d-flex align-ietms-center ${
                      activeStep == 3 ? "border-active" : ""
                    }`}
                  >
                    <div className="round-step1 f-32">3</div>

                    <div className="ms-3 mt-1">
                      <div className="f-18 fw-600">Steps 03</div>
                      <div className="f-14 fw-400 c-gray">Contact Details</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div
                    className={`step-box d-flex align-ietms-center ${
                      activeStep == 4 ? "border-active" : ""
                    }`}
                  >
                    <div className="round-step1 f-32">4</div>

                    <div className="ms-3 mt-1">
                      <div className="f-18 fw-600">Steps 04</div>
                      <div className="f-14 fw-400 c-gray">Promotion</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="b-line"></div>
            </Fragment>
          )}

          <div className="mobile-mt-3">
            {/* steps-1 */}
            <div style={{ display: activeStep == 1 ? "block" : "none" }}>
              <div className="mt-2 tab-type">
                <button
                  onClick={() => handleChangeTransactionType("Sell")}
                  className={`ms-3 btn-tab ${
                    transactionType == "Sell" ? "tabActive-btn" : ""
                  }`}
                >
                  Sell
                </button>

                <button
                  onClick={() => handleChangeTransactionType("Rent")}
                  className={`ms-3 btn-tab ${
                    transactionType == "Rent" ? "tabActive-btn" : ""
                  }`}
                >
                  Rent
                </button>
              </div>

              <AdsInformationForm
                transactionType={transactionType}
                handleOnChangeTab={handleOnChangeTab}
                handleOnSubmitData={handleOnSubmitData}
              />
            </div>

            {/* steps-2*/}
            <div style={{ display: activeStep == 2 ? "block" : "none" }}>
              <AdsInformationImages
                handleOnChangeTab={handleOnChangeTab}
                handleOnSubmitData={handleOnSubmitData}
              />
            </div>

            {/* steps-3*/}
            <div style={{ display: activeStep == 3 ? "block" : "none" }}>
              <AdsInfomationAddress
                handleOnChangeTab={handleOnChangeTab}
                handleOnSubmitData={handleOnSubmitData}
              />
            </div>

            {/* steps-4*/}
            <div style={{ display: activeStep == 4 ? "block" : "none" }}>
              <PromotionPlans
                transactionType={transactionType}
                finalData={finalData}
                handleOnChangeTab={handleOnChangeTab}
                handelOnSubmitPostAdd={handelOnSubmitPostAdd}
              />
            </div>
          </div>
        </div>

        {loginUser?.documentVerificationStatus !== "Verified" && <Lock />}
      </div>

      <div style={{ display: activeStep == 5 ? "block" : "none" }}>
        {renderPostSuccess()}
      </div>
    </DefaultDashboard>
  );
};

export default CreateNewPost;
