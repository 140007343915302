import React from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { LuDot } from "react-icons/lu";
import moment from "moment";
import { formatter } from "../../../../config/common";
import { NavLink } from "react-router-dom";

const MessageItems = (props) => {
  const { message } = props;

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center">
        <div>
          <UserAvatar
            url={message.sender.profileImage}
            name={message.sender.name}
            size="40"
          />
        </div>

        <div className="ms-2 f-16">
          <span className="fw-600">{message.sender.name}</span>
          <span className="c-gray fw-400">
            <LuDot />
            {moment().format("DD-MM-YYYY") ==
            moment(message.createdAt).format("DD-MM-YYYY")
              ? moment(message.createdAt).format("LT")
              : moment(message.createdAt).format("DD MMM,  hh:mm A")}
          </span>
        </div>
      </div>

      <div className="c-gray f-15 fw-400 ms-5">
        {message.message}
        {message.isRelatedToAd && (
          <NavLink
            to={`/ads/${message.isRelatedToAd?.slug}/${message.isRelatedToAd?._id}`}
            state={message.isRelatedToAd}
          >
            <div className="mt-1">
              This message relates to:
              <div className="mt-2 message-related mobile-w-100">
                <img
                  className="product-img mobile-product-img"
                  src={message.isRelatedToAd?.mainImage}
                />

                <div
                  className="f-16 fw-400 ms-3 mobile-one-line "
                  style={{ color: "#464D61" }}
                >
                  {message.isRelatedToAd?.title} (
                  {message.isRelatedToAd?.condition})
                  <div className="c-orange mt-1">
                    {formatter.format(message.isRelatedToAd?.price)}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default MessageItems;
