import React, { Fragment, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import DefaultDashboard from "../../components/dashboard/DefaultDashboard";
import { showToaster } from "../../config/common";
import CouponItem from "./CouponItem";
import PostRequest from "../../services/PostRequest";
import moment from "moment";
import { CouponLoader } from "../../components/loader/AdsItemLoader";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
const CouponsPage = () => {
  const [couponModal, setCouponModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [couponsDetails, setCouponsDetails] = useState([]);

  const [selectType, setSelectType] = useState("Amount");

  const couponCloseModal = () => setCouponModal(false);
  const couponShowModal = () => setCouponModal(true);

  const handelSelectType = (event) => {
    setSelectType(event.target.value);
  };

  const handelSubmitCoupon = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "coupon-id",
      };

      const response = await PostRequest("/coupon/create", data, options);

      showToaster("Coupon applied successfully.");

      setCouponModal(false);

      loadCouponsDetails();
    } catch (error) {
      showToaster(error.message, "error");
      console.log(error.message);
    }
  };

  const loadCouponsDetails = async () => {
    try {
      setLoader(true);
      const response = await PostRequest("/coupons");

      setCouponsDetails(response);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadCouponsDetails();
  }, []);

  return (
    <DefaultDashboard title="Coupons">
      <div className="bg-user-post-ad p-3">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span className="f-20 fw-600 mobile-font-20">Coupons</span>
          <button onClick={couponShowModal} className="coman-btn">
            Add New Coupon
          </button>
        </div>
        <div className="row mt-3">
          <Table className="user-ads-list-table" hover responsive>
            <thead>
              <tr>
                <th className="text-one-line">Valid To</th>
                <th className="text-one-line">Coupon Code</th>
                <th>Value</th>
                <th>Type</th>
                <th className="text-one-line">Max Apply</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* {Array.from({ length: 10 }).map(() => {
                return <CouponItem />;
              })} */}
              {loader ? (
                <CouponLoader />
              ) : (
                <Fragment>
                  {couponsDetails.map((coupon, index) => {
                    return (
                      <CouponItem
                        callBack={loadCouponsDetails}
                        key={index}
                        coupon={coupon}
                      />
                    );
                  })}
                </Fragment>
              )}

              {loader ? (
                ""
              ) : (
                <Fragment>
                  {couponsDetails.length == 0 && (
                    <tr className="t-center">
                      <td colSpan={5}>
                        <h5 className="c-gray mt-3">"0" Coupons</h5>
                      </td>
                    </tr>
                  )}
                </Fragment>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {/* coupon model */}

      <Modal show={couponModal} onHide={couponCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Create Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handelSubmitCoupon}>
            <div className="fw-600 f-16 mt-3">Coupon Code</div>
            <input
              required
              className="my-input mt-1"
              type="text"
              name="couponCode"
              placeholder="Ex. OFF20"
            />

            <div className="mt-3 fw-600 f-16">Type</div>
            <select
              name="type"
              onChange={handelSelectType}
              className="my-input"
            >
              <option value="Amount">Amount</option>
              <option value="Percentage">Percentage</option>
            </select>

            <div className="fw-600 f-16 mt-3">Value</div>

            {selectType == "Percentage" ? (
              <input
                required
                className="my-input mt-1"
                type="number"
                name="value"
                placeholder="Value in Percentage"
                max={100}
                min={0}
                onInput={(e) => {
                  if (e.target.value.length > 3) {
                    e.target.value = e.target.value.slice(0, 3);
                  }
                  if (e.target.value > 100) {
                    e.target.value = 100;
                  }

                  if (e.target.value < 1) {
                    e.target.value = 1;
                  }
                }}
              />
            ) : (
              <input
                required
                className="my-input mt-1"
                type="number"
                name="value"
                placeholder="Value in Amount"
              />
            )}

            <div className="fw-600 f-16 mt-3">
              Max. Apply
              <HiQuestionMarkCircle
                data-tooltip-id="max-value-tooltip"
                className="c-black ms-1"
                size={21}
              />
              <ReactTooltip
                id="max-value-tooltip"
                place="top"
                content="Max. Apply is the maximum number of times this coupon can be used"
                style={{ width: "200px" }}
              />
            </div>
            <input
              required
              className="my-input mt-1"
              type="number"
              placeholder="Maximin Coupon Apply"
              name="maxApply"
              onInput={(e) => {
                if (e.target.value < 1) {
                  e.target.value = 1;
                }
              }}
            />

            <div className="fw-600 f-16 mt-3">
              Valid To
              <HiQuestionMarkCircle
                data-tooltip-id="valid-to-tooltip"
                className="c-black ms-1"
                size={21}
              />
              <ReactTooltip
                id="valid-to-tooltip"
                place="right"
                content="This coupon is valid until this date."
                style={{ width: "200px" }}
              />
            </div>
            <input
              required
              className="my-input mt-1"
              type="date"
              name="validTo"
              min={moment().format("YYYY-MM-DD")}
            />

            <div className="t-end">
              <button id="coupon-id" className="coman-btn mt-3">
                Create Coupon
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </DefaultDashboard>
  );
};

export default CouponsPage;
