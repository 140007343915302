import React, { Fragment, useEffect, useState } from "react";
import { PaymentLoader } from "../../../components/loader/AdsItemLoader";
import PaymentsReceivedItems from "./PaymentsReceivedItems";
import { Table } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";
import OrderPaymentsItem from "./OrderPaymentsItem";

const OrderPaymentComponents = () => {
  const [loader, setLoader] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [orderPaymentDetails, setOrderPaymentDetails] = useState([]);

  const loadOrderPaymentsDetails = async (
    isLoadMore = false,
    hasExtraPayload = {}
  ) => {
    try {
      if (!isLoadMore) {
        setLoader(true);
      }

      const data = {
        type: "Order",
      };

      const activeData = { ...data, ...hasExtraPayload };

      const options = {
        showLoader: "load-more",
      };

      const response = await PostRequest("/user/payments", activeData, options);

      // console.log("responseOrder", response);

      if (isLoadMore) {
        setOrderPaymentDetails((prev) => [...prev, ...response]);
      } else {
        setOrderPaymentDetails(response);
      }

      if (response.length < 20) {
        setHasLoadMore(false);
      } else {
        setHasLoadMore(true);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      if (!isLoadMore) {
        setLoader(false);
      }
    }
  };

  const loaderPaymentData = () => {
    const data = {
      skip: orderPaymentDetails.length,
      // limit: 1,
    };

    // console.log("data", data);

    loadOrderPaymentsDetails(true, data);
  };

  useEffect(() => {
    loadOrderPaymentsDetails();
  }, []);

  return (
    <div className="row mt-4">
      <Table className="user-ads-list-table" hover responsive>
        <thead>
          <tr className="f-13">
            <th className="text-one-line">Date</th>
            <th className="text-one-line t-center">Order</th>
            <th className="text-one-line t-center">Order Amount</th>
            <th className="text-one-line">Services Charge</th>
            <th className="text-one-line">Discount</th>
            <th className="text-one-line t-center">late Fees</th>
            <th className="text-one-line t-center">Total Amount</th>
            <th className="text-one-line">Type</th>
            <th className="text-one-line">Status &nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {loader ? (
            <PaymentLoader />
          ) : (
            <Fragment>
              {orderPaymentDetails.map((payment, index) => {
                return <OrderPaymentsItem key={index} payment={payment} />;
              })}
            </Fragment>
          )}

          {loader ? (
            ""
          ) : (
            <Fragment>
              {orderPaymentDetails.length == 0 && (
                <tr className="t-center">
                  <td colSpan={5}>
                    <h5 className="c-gray mt-3">"0" payments.</h5>
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </Table>

      {loader ? (
        ""
      ) : (
        <Fragment>
          {hasLoadMore && orderPaymentDetails.length != 0 && (
            <div className="t-center">
              <button
                id="load-more"
                onClick={loaderPaymentData}
                className="my-button fw-600 mt-3 w-25 loadMore-btn cursor"
              >
                Load More
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OrderPaymentComponents;
