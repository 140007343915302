import React, { useEffect } from "react";
import Banner from "../components/header/Banner";
import moment from "moment";
import Default from "../components/default/Default";

const TeamsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Default>
      <section>
        <Banner title="Teams and Conditions" />
      </section>

      <section>
        <div className="container gap-35">
          <div className="col-md-12">
            <div className="f-32 fw-600 c-orange">Teams and Condition</div>

            <div className="f-18 fw-600 c-black mt-3">
              Effective Date :{" "}
              <span className="c-gray fw-400">
                {moment().format("Do MMMM YYYY")}
              </span>
              <div className="f-16 c-gray fw-400 mt-1">
                Welcome to Rentoho! By using our services, you agree to comply
                with and be bound by the following terms and conditions of use.
                Please review these terms carefully. If you do not agree with
                these terms, you should not use this site or the services.
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              1. Acceptance of Terms
              <div className="f-16 c-gray fw-400 mt-1">
                By accessing and using the Rentoho app, you accept and agree to
                be bound by the terms and provisions of this agreement. In
                addition, when using these particular services, you shall be
                subject to any posted guidelines or rules applicable to such
                services. Any participation in this service will constitute
                acceptance of this agreement. If you do not agree to abide by
                the above, please do not use this service.
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              2. Description of Services
              <div className="f-16 c-gray fw-400 mt-1">
                Rentoho provides an online platform for users to buy, sell,
                rent, or lease items. Users can post ads, communicate with each
                other, and complete transactions through the app.
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              3. User Responsibilities and Assumption of Risks
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">3.1 User Conduct</div>

                <div className="f-16 c-gray fw-400 mt-1">
                  You agree to use the Rentoho app only for lawful purposes and
                  in a manner that does not infringe the rights of, restrict, or
                  inhibit anyone else's use and enjoyment of the app. Prohibited
                  behavior includes but is not limited to:
                  <ul className="mt-2 ul-height">
                    <li>
                      Harassment or causing distress or inconvenience to any
                      other user.
                    </li>
                    <li>Transmitting obscene or offensive content.</li>
                    <li>
                      Disrupting the normal flow of dialogue within the app.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  3.2 Assumption of Risk
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  You acknowledge and agree that by using the Rentoho app, you
                  assume all responsibility for any risks that may arise from
                  your interactions with other users. These risks include, but
                  are not limited to:
                  <ul className="mt-2 ul-height">
                    <li>
                      The potential for encountering fraudulent or dishonest
                      users.
                    </li>
                    <li>
                      The risk of dealing with users who may misrepresent
                      themselves or the items they are selling, renting, or
                      leasing.
                    </li>
                    <li>
                      The possibility of engaging in transactions that may not
                      meet your expectations.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  3.2 Assumption of Risk
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  Rentoho does not screen users or content posted on the app. As
                  such, you agree that you are solely responsible for your
                  interactions with other users. While Rentoho reserves the
                  right to monitor disputes between users, Rentoho is not
                  obligated to become involved. In no event shall Rentoho be
                  liable for any claims, damages, or losses arising out of or
                  relating to your interactions with other users.
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              4. Limitation of Liability
              <div className="f-16 c-gray fw-400 mt-1">
                In no event shall Rentoho, its officers, directors, employees,
                or agents, be liable to you for any direct, indirect,
                incidental, special, punitive, or consequential damages
                whatsoever resulting from any:
                <ul className="mt-2 ul-height">
                  <li>Errors, mistakes, or inaccuracies of content.</li>
                  <li>
                    Personal injury or property damage, of any nature
                    whatsoever, resulting from your access to and use of our
                    services.
                  </li>
                  <li>
                    Any unauthorized access to or use of our secure servers
                    and/or any and all personal information and/or financial
                    information stored therein.
                  </li>
                  <li>
                    Any interruption or cessation of transmission to or from our
                    services.
                  </li>
                  <li>
                    Any errors or omissions in any content or for any loss or
                    damage of any kind incurred as a result of your use of any
                    content posted, emailed, transmitted, or otherwise made
                    available via the Rentoho app, whether based on warranty,
                    contract, tort, or any other legal theory, and whether or
                    not the company is advised of the possibility of such
                    damages.
                  </li>
                </ul>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              5. User Content
              <div className="f-16 c-gray fw-400 mt-1">
                You are solely responsible for the content that you upload,
                publish, display, link to, or otherwise make available via the
                app. You agree that Rentoho is only acting as a passive conduit
                for your online distribution and publication of your content.
                However, Rentoho reserves the right to remove any content from
                the app at its discretion.
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              6. International Use
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  6.1 Compliance with Local Laws
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  Rentoho operates globally. You agree to comply with all local
                  laws regarding online conduct and acceptable content.
                  Specifically, you agree to comply with all applicable laws
                  regarding the transmission of technical data exported from the
                  country in which you reside.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  6.2 Access Outside [Your Country]
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  If you access the Rentoho app from locations outside of [Your
                  Country], you do so at your own risk and are responsible for
                  compliance with local laws. Rentoho makes no claim that the
                  app or its content is accessible or appropriate outside of
                  [Your Country].
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              7. Intellectual Property
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  7.1 Ownership of Content
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  All content on the Rentoho app, including but not limited to
                  text, graphics, logos, and software, is the property of
                  Rentoho or its content suppliers and is protected by
                  international copyright, trademark, and other intellectual
                  property laws.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  7.2 User-Generated Content
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  By submitting content to the Rentoho app, you grant Rentoho a
                  worldwide, non-exclusive, royalty-free, sub-licensable, and
                  transferable license to use, reproduce, distribute, prepare
                  derivative works of, display, and perform the content in
                  connection with the service provided by the app and across
                  different media.
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              8. Privacy
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  8.1 Collection of Information
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  Rentoho collects and uses your personal information in
                  accordance with its Privacy Policy. By using the app, you
                  consent to the collection and use of your information as
                  outlined in the Privacy Policy.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  8.2 Data Security
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  Rentoho implements reasonable security measures to protect
                  your personal information. However, we cannot guarantee that
                  unauthorized third parties will never be able to defeat those
                  measures or use your personal information for improper
                  purposes. You acknowledge that you provide your personal
                  information at your own risk.
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              9. Dispute Resolution
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  9.1 Governing Law
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  These terms shall be governed by and construed in accordance
                  with the laws of [Your Country/State], without regard to its
                  conflict of law principles.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">9.2 Arbitration</div>

                <div className="f-16 c-gray fw-400 mt-1">
                  For any dispute you have with Rentoho, you agree to first
                  contact us and attempt to resolve the dispute with us
                  informally. If Rentoho has not been able to resolve the
                  dispute with you informally, we each agree to resolve any
                  claim, dispute, or controversy (excluding claims for
                  injunctive or other equitable relief) arising out of or in
                  connection with or relating to these terms by binding
                  arbitration by [Arbitration Organization], under the
                  commercial arbitration rules and procedures then in effect for
                  [Arbitration Organization], except as provided herein. The
                  arbitration will be conducted in [Your City], [Your
                  State/Province], [Your Country], unless you and Rentoho agree
                  otherwise.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  9.3 Class Action Waiver
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  You agree that any arbitration or proceeding shall be limited
                  to the dispute between Rentoho and you individually. To the
                  full extent permitted by law, no arbitration or proceeding
                  shall be joined with any other, including any arbitration or
                  proceeding involving any other current or former user of the
                  app, and no class arbitration proceedings shall be permitted.
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              10. Termination
              <div className="f-16 c-gray fw-400 mt-1">
                Rentoho reserves the right to terminate or suspend your account
                and access to the app at our sole discretion, without notice,
                for conduct that we believe violates these terms or is harmful
                to other users of the app, us, or third parties, or for any
                other reason.
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              11. Miscellaneous
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  11.1 Entire Agreement
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  These terms constitute the entire agreement between you and
                  Rentoho regarding the use of the app and supersede any prior
                  agreements between you and Rentoho relating to your use of the
                  app.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">
                  11.2 Severability
                </div>

                <div className="f-16 c-gray fw-400 mt-1">
                  If any provision of these terms is found to be invalid by any
                  court having competent jurisdiction, the invalidity of such
                  provision shall not affect the validity of the remaining
                  provisions of these terms, which shall remain in full force
                  and effect.
                </div>
              </div>
              <div className="mt-1">
                <div className="f-18 fw-600 c-black mt-2">11.3 No Waiver</div>

                <div className="f-16 c-gray fw-400 mt-1">
                  No waiver of any term of these terms shall be deemed a further
                  or continuing waiver of such term or any other term, and
                  Rentoho's failure to assert any right or provision under these
                  terms shall not constitute a waiver of such right or
                  provision.
                </div>
              </div>
            </div>

            <div className="f-18 fw-600 c-black mt-4">
              12. Contact Information
              <div className="f-16 c-gray fw-400 mt-1">
                If you have any questions about these Terms, please contact us
                at [support@rentoho.com].
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="gap-20"></div>
    </Default>
  );
};

export default TeamsAndConditions;
