import React, { useEffect, useState } from "react";

import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { useLocation, useParams } from "react-router-dom";

import PostRequest from "../../../services/PostRequest";
import * as Yup from "yup";

import AdsInformationForm from "../form/AdsInformationForm";
import AdsInformationImages from "../form/AdsInformationImages";
import AdsInfomationAddress from "../form/AdsInfomationAddress";
import { showToaster } from "../../../config/common";
import LottieLoader from "../../../components/lottieloader/LottieLoader";

import "./create-post.css";

// Validation Schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  price: Yup.string().required("Price is required"),
  category: Yup.string().required("Category is required"),
});

// Initial Values
const adsInformationInitialValue = {
  title: "",
  category: "",
  price: "",
};

const UpdatePost = (props) => {
  const params = useParams();

  const location = useLocation();

  // console.log(location);

  const [transactionType, setTransactionType] = useState("Sell");

  const [activeAds, setActiveAds] = useState(location.state);

  const [finalData, setFinalData] = useState({});

  const handleOnSubmitData = (values) => {
    setFinalData({ ...finalData, ...values });
  };

  const handelPostUpdated = async (values, buttonID) => {
    // console.log("data1010", values);

    try {
      values.transactionType = transactionType;

      const options = {
        showLoader: buttonID,
      };

      const data = {
        ad: params.id,
      };
      const response = await PostRequest(
        "/ad/update",
        { ...finalData, ...values, ...data },
        options
      );

      // console.log("response", response);

      loadUpdatePost();

      showToaster("Ad Update Successfully");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const loadUpdatePost = async () => {
    try {
      const data = {
        ad: params.id,
      };

      const response = await PostRequest("/ads/details", data);

      setActiveAds(response);
      setTransactionType(response.transactionType);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadUpdatePost();
  }, []);

  if (!activeAds) {
    return <LottieLoader />;
  }

  return (
    <DefaultDashboard title={activeAds?.title}>
      <div className={`bg-user-post-ad`}>
        <div className="mobile-mt-3">
          {/* steps-1 */}

          <div className="c-orange mt-4 ms-3 f-20">
            <b>Ads Information</b>
          </div>
          <AdsInformationForm
            activeDefaultValue={activeAds}
            transactionType={transactionType}
            handleOnSubmitData={(values) =>
              handelPostUpdated(values, "ads-loader")
            }
            isEdit={true}
          />

          {/* steps-2*/}

          <div className="c-orange mt-4 ms-3 f-20">
            <b>Description, Features</b>
          </div>

          <AdsInformationImages
            activeDefaultValue={activeAds}
            handleOnSubmitData={(values) =>
              handelPostUpdated(values, "image-loader")
            }
            isEdit={true}
          />

          {/* steps-3*/}

          <div className="c-orange mt-4 ms-3 f-20">
            <b>Post Ads</b>
          </div>

          <AdsInfomationAddress
            activeDefaultValue={activeAds}
            handleOnSubmitData={(values) =>
              handelPostUpdated(values, "address-loader")
            }
            isEdit={true}
          />
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default UpdatePost;
