import React, { useState } from "react";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import Rating from "react-rating";
import { showToaster } from "../../config/common";
import PostRequest from "../../services/PostRequest";
import { useNavigate, useParams } from "react-router-dom";

const Feedback = () => {
  const navigate = useNavigate();

  const params = useParams();

  // console.log("params", params);
  const [feedbackStar, setFeedbackStar] = useState(0);

  const handelFeedbackStar = (star) => {
    setFeedbackStar(star);
  };

  const handelASubmitFeedback = async (event) => {
    try {
      event.preventDefault();

      if (feedbackStar == 0) {
        showToaster("Please give rating", "error");
        return false;
      }
      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      data.rating = feedbackStar;
      data.user = params.user;
      data.order = params.order;

      //  console.log("data", data);

      const options = {
        showLoader: "feedback-load",
      };
      await PostRequest("/feedback", data, options);
      showToaster("Thank you for your feedback.");

      navigate("/home");
    } catch (error) {
      console.log("error", error.message);
    }
  };

  return (
    <div style={{ backgroundColor: "#f8a200" }}>
      <div className="container">
        <div className="row">
          <div className=" offset-md-2 col-md-8">
            <div
              style={{ height: "100vh" }}
              className="d-flex align-items-center"
            >
              <div
                className="w-100"
                style={{
                  backgroundColor: "white",
                  borderRadius: "6px",
                  padding: " 60px 30px",
                }}
              >
                <div className="text-center">
                  <img height={40} src="/images/logo.png" alt="" />

                  <h3 className="mt-4">
                    Your Feedback is{" "}
                    <span className="c-orange">"Valuable"</span>
                  </h3>
                </div>

                <form onSubmit={handelASubmitFeedback} className="gap-70">
                  {/* <Rating
                initialRating={feedbackStar}
                onChange={handelFeedbackStar}
                emptySymbol={<CiStar size={30} />}
                fullSymbol={<FaStar className="c-orange" size={25} />}
              /> */}

                  <Rating
                    initialRating={feedbackStar}
                    onChange={handelFeedbackStar}
                    emptySymbol={<CiStar size={35} />}
                    fullSymbol={<FaStar className="c-orange" size={30} />}
                  />

                  <div className="fw-600 f-18 mt-3">Messages</div>
                  <textarea
                    maxLength={500}
                    name="message"
                    style={{ border: "1px solid rgb(185 185 185)" }}
                    className="text-area-input cursor w-100 gap-10"
                    rows="6"
                    placeholder="Share our thoughts feedback...."
                    required
                  ></textarea>

                  <div className="t-end">
                    <button id="feedback-load" className="coman-btn gap-20">
                      Submit Feedback
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
