import React, { Fragment, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import { AiOutlinePlusCircle } from "react-icons/ai";
import UserAvatar from "../../user/UserAvatar";
import { getAuthUser } from "../../../config/common";
import { IoMdLogOut } from "react-icons/io";
import { IoNotificationsOutline } from "react-icons/io5";
import NotificationComponents from "../../notification/NotificationComponents";
import PostRequest from "../../../services/PostRequest";
import { BsChatDots } from "react-icons/bs";

const MobileHeader = () => {
  const navigate = useNavigate();

  const loginUser = getAuthUser();
  const [sidebarShow, setSidebarShow] = useState(false);
  // const [notificationDrop, setNotificationDrop] = useState(false);
  const [userMessageNotificationCount, setUserMessageNotificationCount] =
    useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  // const handelNotificationDropdown = () => {
  //   setNotificationDrop(!notificationDrop);
  // };

  const loadNotificationCount = async () => {
    try {
      const response = await PostRequest("/notifications");

      // console.log("response", response);

      setNotifications(response);
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  const loadUserMessageCount = async () => {
    try {
      const response = await PostRequest("/chats/unread/count");

      setUserMessageNotificationCount(response);
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  const handelLogout = () => {
    window.localStorage.clear();
    navigate("/login");
  };

  const handelHome = () => {
    navigate("/home");
  };

  const handelUserDashboard = () => {
    navigate("/user/dashboard");
  };
  const handelChat = () => {
    navigate("/user/messages");
  };

  const handelNotificationPage = () => {
    navigate("/user/notifications");
  };

  useEffect(() => {
    loadUserMessageCount();
    loadNotificationCount();
  }, []);

  useEffect(() => {
    var filterCount = notifications.filter((item) => item.isRead == false);

    setNotificationCount(filterCount);
  }, [notifications]);

  return (
    <div>
      <div className="mobile-header">
        <div onClick={handelHome}>
          <img
            className="logo-img mt-1 cursor mobile-logo-img"
            src="/images/logo.png"
            alt="image_logo"
          />
        </div>

        <div className="d-flex align-items-center">
          {loginUser && (
            <div className="d-flex align-items-center">
              <div className="rel">
                <div>
                  <IoNotificationsOutline
                    className="cursor"
                    size={23}
                    color="#767E94"
                    onClick={handelNotificationPage}
                  />
                </div>
                {notificationCount.length != 0 && (
                  <div className="message-count-round">
                    {notificationCount.length}
                  </div>
                )}

                {/* {notificationDrop && (
              <div className="notification-position">
                <NotificationComponents notifications={notifications} />
              </div>
            )} */}
              </div>

              <div className="rel ms-3" onClick={handelChat}>
                <div>
                  <BsChatDots className="cursor" size={23} color="#767E94" />
                </div>
                {userMessageNotificationCount != 0 && (
                  <div className="message-count-round">
                    {userMessageNotificationCount}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="ms-3">
            <FaBars onClick={() => setSidebarShow(true)} size={21} />
          </div>
        </div>
      </div>

      {sidebarShow && (
        <div className="mobile-sidebar">
          <span
            onClick={() => setSidebarShow(false)}
            className="close-sidebar-bg right-float"
          >
            <VscClose size={25} />
          </span>

          <div className="mt-4 mobile-sidebar-padding">
            {/* <div className="header-menu-title">Menu</div> */}

            {loginUser && (
              <div className="user-profile-bottom-fixed">
                <div
                  onClick={handelUserDashboard}
                  className="d-flex align-items-center"
                >
                  <UserAvatar
                    url={loginUser?.profileImage}
                    name={loginUser?.name}
                    size="55"
                  />
                  <div className="ms-2">
                    <div className="fw-600 f-16">{loginUser?.name}</div>
                    <div className="f-12">{loginUser?.email}</div>
                  </div>
                </div>
                <div onClick={handelLogout} className="f-16">
                  <IoMdLogOut size={25} />
                </div>
              </div>
            )}

            <hr />

            <div className="mobile-menu-list">
              <div className="mt-3">
                <NavLink className="c-black" to="/home">
                  Home
                </NavLink>
              </div>
              <div>
                <NavLink className="c-black" to="/ads/listings">
                  All listings
                </NavLink>
              </div>
              <div>
                <NavLink className="c-black" to="/about">
                  About
                </NavLink>
              </div>
              <div>
                <NavLink className="c-black" to="/contact">
                  Contact
                </NavLink>
              </div>
              <div>
                <NavLink className="c-black" to="/how-it-works">
                  How It Works
                </NavLink>
              </div>
              {!loginUser && (
                <Fragment>
                  <div>
                    <NavLink className="c-black" to="/login">
                      Login
                    </NavLink>
                  </div>
                  <div>
                    <NavLink className="c-black" to="/register">
                      Register
                    </NavLink>
                  </div>
                </Fragment>
              )}
              <div>
                <NavLink className="c-black" to="/user/post/create">
                  Post ad
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
