import React, { Fragment, useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import PostRequest from "../../../../services/PostRequest";
import ConversionsItems from "./ConversionsItems";

const Conversions = (props) => {
  const { isNewUser = false } = props;

  const [conversions, setConversions] = useState([]);

  // console.log("conversions", conversions);

  const [search, setSearch] = useState("");

  const loadConversionList = async () => {
    try {
      const response = await PostRequest("/conversions");
      setConversions(response);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handelSearchMessage = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    loadConversionList();
  }, []);

  return (
    <div>
      <div className="p-15">
        <div className="f-20 fw-600">Message</div>

        <div className="input-head gap-15">
          <IoSearchOutline size={20} color="#F59449" />
          <input
            type="text"
            onChange={handelSearchMessage}
            className="input-search ms-2"
            placeholder="Search"
          />
        </div>
      </div>

      <div className={`user-items`}>
        <div className="row">
          {conversions
            .filter((item) =>
              item.userDetails.name.toLowerCase().includes(search)
            )
            .map((user, index) => {
              return (
                <div key={index} className="col-md-12">
                  <ConversionsItems lastMessage={user} />
                </div>
              );
            })}

          {conversions.length == 0 && (
            <Fragment>
              <div className="t-center c-gray f-18 fw-600">
                "0" conversions.
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Conversions;
