import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import PostRequest from "../../../services/PostRequest";
import { Table } from "react-bootstrap";
import PaymentsReceivedItems from "./PaymentsReceivedItems";
import { showToaster } from "../../../config/common";
// import { Country } from "country-state-city";
import { useParams } from "react-router-dom";
import { PaymentLoader } from "../../../components/loader/AdsItemLoader";
import SecurityDepositComponents from "./SecurityDepositComponents";
import OrderPaymentComponents from "./OrderPaymentComponents";

const PaymentsDetails = () => {
  const [paymentsTab, setPaymentsTab] = useState("Order Payments");

  const handelSellerTab = (tab) => {
    setPaymentsTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultDashboard title="Payment Details">
      <div className="bg-user-post-ad p-20">
        <span className="f-20 fw-600 mobile-font-20">Payments</span>

        <div className="p-15 mt-3 mobile-mt-0">
          <span
            onClick={() => handelSellerTab("Order Payments")}
            className={`tab-span mobile-font-13 ${
              paymentsTab == "Order Payments" ? "seller-active-tab" : ""
            }`}
          >
            Order Payments
          </span>
          <span
            onClick={() => handelSellerTab("Security Deposit")}
            className={`tab-span mobile-font-13 ${
              paymentsTab == "Security Deposit" ? "seller-active-tab" : ""
            }`}
          >
            Security Deposit
          </span>
        </div>
        <div className="b-line-bottom"></div>

        {paymentsTab == "Order Payments" && <OrderPaymentComponents />}

        {paymentsTab == "Security Deposit" && <SecurityDepositComponents />}
      </div>
    </DefaultDashboard>
  );
};

export default PaymentsDetails;
