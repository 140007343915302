import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { IoSearchOutline } from "react-icons/io5";
import { Table } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";
import FavoriteItems from "../../../components/products/favorite/FavoriteItems";
import { MyFavoriteLoader } from "../../../components/loader/AdsItemLoader";

var searchTimeout;
const MyFavoriteAds = () => {
  const [userFavorites, setUserFavorites] = useState([]);
  // console.log("userFavorites", userFavorites);

  const [loader, setLoader] = useState(false);

  const [activeFilters, setActiveFilters] = useState({});

  const handelSearch = (event) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setActiveFilters((prev) => ({ ...prev, search: event.target.value }));
    }, 500);
  };

  const loadFavorite = async () => {
    try {
      setLoader(true);

      const response = await PostRequest("/user/favorites", activeFilters);
      setUserFavorites(response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadFavorite();
  }, [activeFilters]);
  return (
    <DefaultDashboard title="Favorites">
      <div className="bg-user-post-ad p-3">
        <div className="row">
          <div className="col-md-12">
            <div className="input-head">
              <IoSearchOutline size={20} color="#F59449" />
              <input
                onChange={handelSearch}
                type="text"
                className="input-search ms-2"
                placeholder="Ads tittle, keyword..."
              />
            </div>
          </div>
        </div>

        <div className="gap-30">
          <div className="row">
            <Table className="user-ads-list-table" hover responsive>
              <thead>
                <tr>
                  <th width="60%">Ads</th>
                  <th>Type</th>
                  <th>Prices</th>
                  <th width="110px">Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <MyFavoriteLoader />
                ) : (
                  <Fragment>
                    {userFavorites.map((favorite, index) => {
                      return (
                        <FavoriteItems
                          callback={loadFavorite}
                          key={index}
                          data={favorite}
                        />
                      );
                    })}
                  </Fragment>
                )}

                {loader ? (
                  ""
                ) : (
                  <Fragment>
                    {userFavorites.length == 0 && (
                      <tr className="t-center">
                        <td colSpan={5}>
                          <h5 className="c-gray mt-3">"0" favorites.</h5>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default MyFavoriteAds;
