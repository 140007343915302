import React, { Fragment, useState } from "react";
import PostRequest from "../../../services/PostRequest";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { FaArrowRightLong } from "react-icons/fa6";
import * as Yup from "yup";
import { Formik } from "formik";
import { showToaster } from "../../../config/common";
import { FaStarOfLife } from "react-icons/fa";
// Validation Schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("description is required"),
});

// Initial Values

const AdsInformationImages = (props) => {
  const {
    handleOnChangeTab,
    handleOnSubmitData,
    activeDefaultValue = {},
    isEdit = false,
  } = props;

  // console.log("activeDefaultValue", activeDefaultValue);

  const [mainImage, setMainImage] = useState([]);
  const [multipleFile, setMultipleFile] = useState([]);

  const adsInformationInitialValue = {
    description: activeDefaultValue.description || "",
  };

  // dropzone image

  const updateMainImg = (incomingFiles) => {
    setMainImage(incomingFiles);
  };
  const onDelete = (id) => {
    setMainImage(mainImage.filter((x) => x.id !== id));
  };

  const updateFilesMultiple = (incomingFiles) => {
    setMultipleFile(incomingFiles);
  };
  const onDeleteMultiple = (id) => {
    setMultipleFile(multipleFile.filter((x) => x.id !== id));
  };

  const handelInformationFormImage = async (values) => {
    try {
      const formData = new FormData();
      mainImage.map((file) => {
        // console.log(file);
        if (file.errors.length == 0) {
          formData.append("image", file.file);
        }
      });

      const options = {
        showLoader: "img-loader",
        isMultiMedia: true,
      };

      const responseURL = await PostRequest("/upload/one", formData, options);
      values.mainImage = responseURL;
      formData.delete("image");

      multipleFile.map((file) => {
        if (file.errors.length == 0) {
          formData.append("images", file.file);
        }
      });

      const responseURLList = await PostRequest(
        "/upload/multiple",
        formData,
        options
      );

      values.images = responseURLList;
      if (!isEdit) {
        handleOnChangeTab(3);
      }
      handleOnSubmitData(values);
    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
    }
  };

  return (
    <div className={`${isEdit ? "p-3" : "p-20"} `}>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handelInformationFormImage(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="f-16 fw-600 mt-1">Description</div>
              <textarea
                required
                className="text-area-input cursor w-100"
                rows="4"
                placeholder="Description"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              ></textarea>
            </div>

            {/* main image */}
            <div className="mt-3">
              <div className="f-16 fw-600 mt-1">
                {" "}
                Main Photo <FaStarOfLife size={13} />
              </div>
              <Dropzone
                style={{ height: "100px" }}
                onChange={updateMainImg}
                minHeight="195px"
                value={mainImage}
                maxFiles={6}
                multiple={false}
                required
                maxFileSize={25000000}
                accept=".png,image/*"
                url="https://my-awsome-server/upload-my-file"
                fakeUploading
                disableScroll
              >
                {mainImage.length > 0 &&
                  mainImage.map((file) => (
                    <FileItem
                      {...file}
                      key={file.id}
                      onDelete={onDelete}
                      preview
                      hd
                    />
                  ))}
              </Dropzone>
            </div>

            {isEdit && (
              <Fragment>
                <img
                  className="product-img-update mt-2"
                  src={activeDefaultValue.mainImage}
                  alt="image"
                />
              </Fragment>
            )}

            {/* multiple Images */}

            <div className="mt-3">
              <div className="f-16 fw-600 mt-1">
                Additional Photos (max. 6) <FaStarOfLife size={13} />
              </div>
              <Dropzone
                style={{ height: "100px" }}
                onChange={updateFilesMultiple}
                minHeight="195px"
                value={multipleFile}
                maxFiles={6}
                maxFileSize={25000000}
                accept=".png,image/*"
                url="https://my-awsome-server/upload-my-file"
                fakeUploading
                disableScroll
              >
                {multipleFile.length > 0 &&
                  multipleFile.map((file) => (
                    <FileItem
                      {...file}
                      key={file.id}
                      onDelete={onDeleteMultiple}
                      preview
                      hd
                    />
                  ))}
              </Dropzone>
            </div>

            {isEdit && (
              <Fragment>
                {activeDefaultValue.images.map((img, index) => {
                  return (
                    <img
                      key={index}
                      className="product-img-update mt-2 me-3"
                      src={img}
                      alt="image"
                    />
                  );
                })}
              </Fragment>
            )}

            <div className="gap-30">
              {isEdit ? (
                <div className="t-end">
                  <button
                    id="image-loader"
                    type="submit"
                    className="coman-btn ms-3 cursor"
                  >
                    Save Changes
                  </button>
                </div>
              ) : (
                <div className="t-end">
                  <button
                    type="button"
                    onClick={() => handleOnChangeTab(1)}
                    className="coman-btn-boder"
                  >
                    Previous
                  </button>

                  <button
                    id="img-loader"
                    type="submit"
                    className="coman-btn ms-3"
                    disabled={
                      !isValid ||
                      !dirty ||
                      mainImage.length == 0 ||
                      multipleFile.length == 0
                    }
                  >
                    Next Step &nbsp;
                    <FaArrowRightLong />
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdsInformationImages;
