import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { Fragment, useEffect, useState } from "react";
import PostRequest from "../../../services/PostRequest";
import { PaymentFormLoader } from "../../../components/loader/AdsItemLoader";
import { formatter, isMobile, showToaster } from "../../../config/common";
import { LuLoader2 } from "react-icons/lu";
import PaymentButton from "./PaymentButton";
import { AiOutlineClose } from "react-icons/ai";
// const stripePromise = loadStripe(
//   "pk_test_51PfeTaDI5UJvScqL8WUMUAP1mI63f7qSHK40eaH0UcJQdfehNgc5LNPhyAc9dokButtYmmqBPY2Qa8be9ClkmQkM00qPK7jQIL"
// );

var hasCp = false;

function generateRandomNumber() {
  return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
}

const OrderPaymentForm = (props) => {
  const {
    handleOnChangeTab,
    payment,
    handleOnSubmitData,
    numberOfDays = 1,
  } = props;

  // console.log("payment", payment);

  const [loader, setLoader] = useState(false);

  const [isCouponCodeOpen, setIsCouponCodeOpen] = useState(false);
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    var _totalAmount = 0;
    var actualOrderAmount = 0;
    var discountAmount = 0;

    if (payment.transactionType == "Rent") {
      var perDayAmount = payment.price;
      if (payment.priceType == "Per Month") {
        perDayAmount = payment.price / 30;
      }

      if (payment.priceType == "Per Year") {
        perDayAmount = payment.price / 365;
      }

      actualOrderAmount = perDayAmount * numberOfDays;
    } else {
      actualOrderAmount = payment.price;
      _totalAmount = payment.price;
    }

    _totalAmount = actualOrderAmount + Number(payment.securityDeposit);

    if (isDiscountApplied) {
      discountAmount = isDiscountApplied.value;
      if (isDiscountApplied.type == "Percentage") {
        discountAmount = (actualOrderAmount * isDiscountApplied.value) / 100;
      }
      _totalAmount = (_totalAmount - discountAmount).toFixed(2);

      // console.log("opopf", _totalAmount);

      hasCp = isDiscountApplied;
    }

    setTotalAmount(_totalAmount);
    setDiscount(Number(discountAmount.toFixed(2)));

    // console.log("_totalAmount", _totalAmount);
  }, [isDiscountApplied]);

  const handelPaymentSuccess = (paymentSuccessResponse) => {
    const data = {
      paymentId: paymentSuccessResponse.id,
      coupon: isDiscountApplied?._id,
    };
    handleOnSubmitData(data);
  };

  const handelApplyCode = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "applyCode-loader",
      };

      data.ad = payment._id;

      const response = await PostRequest("/coupon/apply", data, options);

      showToaster("Coupon applied successfully!");
      setIsDiscountApplied(response);
    } catch (error) {
      console.log(error.message);
      hasCp = false;
      setIsDiscountApplied(false);
      showToaster(error.message, "error");
    }
  };

  const handelDeleteCouponCode = async () => {
    try {
      setIsCouponCodeOpen(false);
      setIsDiscountApplied(false);
      setDiscount(0);
      hasCp = false;
    } catch (error) {
      console.log(error.message);
    }
  };

  // console.log("isDiscountApplied", isDiscountApplied);

  return (
    <div>
      {loader ? (
        <PaymentFormLoader />
      ) : (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div>
              {payment.transactionType == "Rent" && (
                <div className="mb-2">
                  <div className="d-flex align-items-center f-16 fw-400 mt-1 justify-content-between">
                    <div>
                      <div>Total Rent Amount</div>
                      <div className="c-gray f-14">
                        ({`${numberOfDays} Days`})
                      </div>
                    </div>

                    <div className="fw-600 f-16">
                      {formatter.format(
                        totalAmount + discount - Number(payment.securityDeposit)
                      )}
                    </div>
                  </div>

                  {Number(payment.securityDeposit) > 0 && (
                    <div className="d-flex align-items-center f-16 fw-400 mt-2 justify-content-between">
                      <div>
                        <div>Deposit Amount</div>
                        <div className="c-gray f-14">(Refundable)</div>
                      </div>

                      <div className="fw-600 f-16">
                        {formatter.format(Number(payment.securityDeposit))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isDiscountApplied && (
                <Fragment>
                  <div className="d-flex align-items-center f-16 fw-400 justify-content-between">
                    <div className="text-success">
                      {isDiscountApplied.couponCode} Applied
                    </div>

                    <div
                      onClick={handelDeleteCouponCode}
                      className="fw-600 f-16"
                    >
                      <AiOutlineClose color="red" size={23} />
                    </div>
                  </div>

                  <div className="d-flex align-items-center f-16 fw-400 justify-content-between mt-2">
                    <div className="text-success">Discount</div>

                    <div className="fw-600 f-16 text-success">
                      {formatter.format(discount)}
                    </div>
                  </div>
                </Fragment>
              )}

              <div className="d-flex align-items-center f-16 fw-400 mb-4 justify-content-between mt-1">
                <div className="fw-600">Total Amount</div>

                <div className="fw-600 f-16">
                  {formatter.format(Number(totalAmount))}
                </div>
              </div>

              {isCouponCodeOpen ? (
                <div
                  onClick={handelDeleteCouponCode}
                  className="mb-2 text-danger text-underline cursor"
                >
                  Cancel
                </div>
              ) : (
                <div
                  onClick={() => setIsCouponCodeOpen(true)}
                  className="mb-2 text-success text-underline cursor"
                >
                  Have a coupon code?
                </div>
              )}

              {isCouponCodeOpen && (
                <div className="mb-3">
                  <form onSubmit={handelApplyCode}>
                    <div className="d-flex justify-content-between align-items-center">
                      <input
                        className={`my-input mt-1 w-50`}
                        type="text"
                        placeholder="Enter Coupon Code"
                        required
                        name="couponCode"
                      />

                      <button id="applyCode-loader" className="coman-btn">
                        Apply Code
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {isDiscountApplied ? (
                <div className="m-auto">
                  <PaymentButton
                    key={`1_${generateRandomNumber()}`}
                    onPaymentSuccess={handelPaymentSuccess}
                    totalAmount={totalAmount}
                    payment={payment}
                    numberOfDays={numberOfDays}
                    coupon={isDiscountApplied?._id}
                  />
                </div>
              ) : (
                <div className="m-auto">
                  <PaymentButton
                    key={`2_${generateRandomNumber()}`}
                    onPaymentSuccess={handelPaymentSuccess}
                    totalAmount={totalAmount}
                    payment={payment}
                    numberOfDays={numberOfDays}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// STRIPE

// const PaymentForm = ({
//   handleOnChangeTab,
//   handleOnSubmitData,
//   totalAmount,
//   payment,
// }) => {
//   const [isLoading, setIsLoading] = useState(false);

//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (!stripe || !elements) {
//         return;
//       }
//       setIsLoading(true);
//       const confirmPayment = await stripe.confirmPayment({
//         elements,
//         redirect: "if_required",
//       });
//       if (confirmPayment.error) {
//         if (
//           confirmPayment.error.type === "card_error" ||
//           confirmPayment.error.type === "validation_error"
//         ) {
//           alert(confirmPayment.error.message);
//         } else {
//           alert(confirmPayment.error.message);
//         }
//       } else {
//         // Payment succeeded, proceed with API call
//         const data = {
//           paymentId: confirmPayment.paymentIntent.id,
//         };
//         handleOnSubmitData(data);
//       }
//     } catch (error) {
//       console.error("An unexpected error occurred:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div>
//       <div id="payment-element">
//         <form id="payment-form" onSubmit={handleSubmit}>
//           <PaymentElement id="payment-element" />
//           <div className="gap-30 t-end">
//             <button
//               type="button"
//               onClick={() => {
//                 payment?.transactionType === "Rent"
//                   ? handleOnChangeTab("Sign e-Contract")
//                   : handleOnChangeTab("Shipping Details");
//               }}
//               className="coman-btn-boder"
//             >
//               Previous
//             </button>

//             <button
//               id="orderloader-btn"
//               type="submit"
//               disabled={isLoading}
//               className="coman-btn ms-3"
//             >
//               {isLoading && <LuLoader2 />} Pay {formatter.format(totalAmount)}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

export default OrderPaymentForm;
