import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Banner from "../../components/header/Banner";
import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
import Default from "../../components/default/Default";

const ForgotPassPage = () => {
  const navigate = useNavigate();

  const handelForgotPassword = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "forget-button",
      };
      const response = await PostRequest("/send-otp", data, options);

      // console.log("response",response);

      navigate("/reset-password", { state: response.token });
    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
    }
  };

  return (
    <Default>
      <section>
        <Banner title="Forgot password" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-in-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <form onSubmit={handelForgotPassword}>
                  <div className="login-bg rel">
                    <div className="t-center fw-600 mt-3">
                      <h3>Forgot password?</h3>
                    </div>
                    <div className="fw-600 mt-5 f-16 t-center c-gray">
                      No worries! It happens to the best of us. <br /> Just
                      enter your email address below, and we'll <br /> send you
                      instructions on how to reset your password.
                    </div>

                    <div
                      className="w-100"
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        marginTop: "50px",
                      }}
                    >
                      <div className="input-login cursor">
                        <input
                          required
                          name="email"
                          className="input-sigin-1"
                          type="email"
                          placeholder="Email Address"
                        />
                      </div>

                      <button
                        id="forget-button"
                        className="my-button padd-btn-login w-100 mt-4 cursor"
                      >
                        Send Otp
                      </button>

                      <div className="mt-3 t-center f-16 fw-400 c-gray">
                        You remember your password
                        <NavLink to="/login">
                          <span className="c-orange cursor"> Login?</span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default ForgotPassPage;
