import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getAuthUser } from "../config/common";
import { NavLink } from "react-router-dom";

const WelcomePrompting = (props) => {
  const [promptModal, setPromptModal] = useState(true);

  const handleClose = () => setPromptModal(false);

  const handleOkay = () => {
    handleClose();
    window.localStorage.setItem("Welcome", true);
  };

  const authUser = getAuthUser();

  if (!authUser || window.localStorage.getItem("Welcome")) return null;
  if (authUser && authUser.isVerifiedUser) return null;

  return (
    <Modal
      // size="lg"
      show={promptModal}
      onHide={handleClose}
      className="model-title-block"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-orange fw-700">
          Complete Your Account Setup
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            To enhance your experience and start selling items, please complete
            the following steps.
          </div>

          <ul className="mt-2">
            <li>
              <span className="f-18 fw-600">
                <NavLink
                  to="/user/profile/verify"
                  style={{ textDecoration: "underline", color: "black" }}
                >
                  <span className="cursor">EKYC Verification</span>
                </NavLink>
                :
              </span>{" "}
              Ensure you're verified to sell items on our platform.
            </li>
            <li className="mt-2">
              <NavLink
                to="/user/payment/settings"
                style={{ textDecoration: "underline", color: "black" }}
              >
                <span className="f-18 fw-600 cursor">Payment Setup</span>
              </NavLink>
              : Set up your payment details to receive your earnings seamlessly.
            </li>
          </ul>

          <div>
            Thank you for completing these steps to unlock full access to our
            services!
          </div>

          <div className="t-center">
            <button onClick={handleOkay} className="coman-btn cursor mt-3">
              Okay
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomePrompting;
