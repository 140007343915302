import { GoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import { LuEye } from "react-icons/lu";
import { NavLink, useNavigate } from "react-router-dom";
import PostRequest from "../services/PostRequest";
import { showToaster } from "../config/common";

const LoginFormComponent = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleOnSubmitLoginForm = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "login-button",
      };
      const response = await PostRequest("/login", data, options);
      if (response.token) {
        window.localStorage.setItem("auth", JSON.stringify(response));
        window.localStorage.setItem("TOKEN", response.token);
        showToaster("Welcome back!");

        if (window.location.href.match("login")) {
          navigate("/home");
        }

        if (props.callback) {
          props.callback();
        }
      } else {
        navigate("/verification", { state: response._id });
      }
    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
    }
  };

  const handelLoginGoogle = async (credentialResponse) => {
    try {
      const data = {
        token: credentialResponse.credential,
      };

      const response = await PostRequest("/login/google", data);

      if (!response.emailVerified) {
        navigate("/verification", { state: response.token });
      } else {
        window.localStorage.setItem("auth", JSON.stringify(response));
        window.localStorage.setItem("TOKEN", response.token);

        if (window.location.href.match("login")) {
          navigate("/home");
        }

        if (props.callback) {
          props.callback();
        }
      }
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  const handelShowpassword = () => {
    setShow(!show);
  };

  return (
    <div>
      <div className="t-center fw-600">
        <h3>Sign In</h3>
      </div>
      <div className="row mt-4 mobile-google-btn">
        <div className="col-md-12">
          <div style={{ width: "100%" }} className="mobile-mt-2 d-center">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handelLoginGoogle(credentialResponse);
              }}

              // containerProps={{
              //   style: { width: "100%" },
              // }}
            />
          </div>
        </div>
        {/* <div className="col-md-6">
      

          <div className="btn-google cursor">
            <FaFacebookF color="#1877F2" size={17} /> &nbsp; Sign in with
            Facebook
          </div>
        </div> */}
      </div>

      <div className="my-4 d-flex align-items-center justify-content-between">
        <div className="login-with-email-border"></div>

        <div className="f-16 other-login mobile-font">
          or Sign in with Email
        </div>

        <div className="login-with-email-border"></div>
      </div>

      <form onSubmit={handleOnSubmitLoginForm}>
        <div className="mt-3 input-login cursor">
          <input
            required
            name="email"
            className="input-sigin-1"
            type="email"
            placeholder="Email Address"
          />
        </div>

        <div className="mt-3 input-login rel cursor">
          <input
            required
            name="password"
            id="passwordid"
            className="input-sigin-1"
            type={show == true ? "text" : "password"}
            placeholder="Password"
          />

          <span className="mobile-eye-password" onClick={handelShowpassword}>
            {!show ? (
              <LuEye color="#191F33" size={20} />
            ) : (
              <FaRegEyeSlash color="#191F33" size={20} />
            )}
          </span>
        </div>
        <NavLink to="/forgot-password">
          <div className="mt-3 f-14 c-orange cursor t-end">Forgot password</div>
        </NavLink>

        <button
          id="login-button"
          className="my-button padd-btn-login w-100 mt-4 cursor"
        >
          Sign In <GoArrowRight size={20} />
        </button>
      </form>

      <div className="mt-3 t-center f-16 fw-600">
        Don’t have account?{" "}
        <NavLink to="/register">
          <span className="c-orange cursor"> Sign Up</span>
        </NavLink>
      </div>
    </div>
  );
};

export default LoginFormComponent;
