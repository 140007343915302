import React, { useState } from "react";
import { formatter, PaymentsReceivedColor } from "../../../config/common";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { MdHelp } from "react-icons/md";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
const SecurityDepositItem = ({ payment }) => {
  const [show, setShow] = useState(false);

  // console.log("payment", payment);

  var totalAmount = 0;

  totalAmount = payment.totalAmount + (payment.settlementAmount || 0);

  // console.log("totalAmount", totalAmount);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr className="th-title-50 f-15">
      <td className="f-15 text-one-line">
        {moment(payment.createdAt).format("lll")}
      </td>

      <td className="c-gray text-one-line text-center">
        <NavLink
          to={`/user/orders/details/${payment.order.orderNumber}`}
          className="cursor"
        >
          {payment.order.orderNumber}
        </NavLink>
      </td>
      <td className="t-center">{formatter.format(payment.depositAmount)}</td>
      {/* <td className="t-center">
        {formatter.format(payment.order.securityDeposit)}
      </td> */}
      <td
        data-tooltip-id={`late-fees-${payment._id}`}
        className="text-danger text-center"
      >
        {formatter.format(payment.lateFees)}
        <ReactTooltip
          id={`late-fees-${payment._id}`}
          place="top"
          content={`Total ${payment.totalLateDays} days late return`}
          // style={{ width: "300px"  }}
        />
      </td>

      <td className="t-center">
        {formatter.format(totalAmount)}
        {payment.isSettlement && (
          <span className="ms-1">
            <MdHelp
              size={20}
              data-tooltip-id={payment._id}
              className="cursor"
            />
            <ReactTooltip
              id={payment._id}
              place="top"
              content={`Total Payable amount was ${formatter.format(
                payment.totalAmount
              )}, and after settlement by rentoho, the payable amount is ${formatter.format(
                totalAmount
              )}, For more information please check order details or contact to support. `}
              style={{ width: "200px" }}
            />
          </span>
        )}
      </td>
      <td className="text-one-line">
        <div className="d-flex align-items-center justify-content-between">
          <div className={` ${PaymentsReceivedColor(payment.status)}`}>
            {payment.status}{" "}
            {(payment.status == "Failed" || payment.status == "Canceled") && (
              <span>
                <MdHelp
                  size={20}
                  data-tooltip-id={`Failed-${payment._id}`}
                  className="cursor"
                />

                <ReactTooltip
                  id={`Failed-${payment._id}`}
                  place="top"
                  content={payment.transactionFailedReason}
                  // style={{ width: "300px"  }}
                />
              </span>
            )}
            {payment.status == "On Hold" && (
              <span>
                <MdHelp
                  size={20}
                  data-tooltip-id={`onHold-${payment._id}`}
                  className="cursor"
                />

                <ReactTooltip
                  id={`onHold-${payment._id}`}
                  place="top"
                  content="Payment is currently on hold due to an ongoing order report. Please check your order details or contact support for further assistance"
                  // style={{ width: "300px"  }}
                />
              </span>
            )}
          </div>
          <button
            onClick={handleShow}
            className="btn-orange-outline mobile-edit-btn mobile-ms-2"
          >
            View
          </button>
        </div>
      </td>

      {/* Model Payment Information */}

      <Modal show={show} onHide={handleClose} className="model-title-block">
        <Modal.Header closeButton>
          <Modal.Title className="c-orange fw-700">
            Payments Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="c-gray">Order Number : </div>

            <div className="ms-2" style={{ letterSpacing: "2px" }}>
              {payment.order.orderNumber}
            </div>
          </div>
          {payment.order.status == "Canaled" && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Order Cancel Date : </div>

              <div className="ms-2" style={{ letterSpacing: "2px" }}>
                {moment(payment.order.orderCancelDate).format("DD/MM/YYYY")}
              </div>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Payment Id : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.order.paymentId}
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Transition Id : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.transactionId}
            </div>
          </div>
          {payment.depositAmount && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Security Deposit : </div>

              <div className="ms-2">
                {formatter.format(payment.depositAmount)}
              </div>
            </div>
          )}

          {payment.itemAmount != "0" && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Item Amount : </div>

              <div className="ms-2">{formatter.format(payment.itemAmount)}</div>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Total Amount : </div>

            <div className="ms-2">{formatter.format(payment.totalAmount)}</div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Payment Status : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.status}
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Type : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.type}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default SecurityDepositItem;
