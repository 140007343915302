import React from "react";
import UserAvatar from "../user/UserAvatar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toSlug } from "../../config/common";
const NotificationItems = ({ notification }) => {
  // console.log("notification", notification);

  const navigate = useNavigate("");

  const handelNotificationType = (type) => {
    // console.log("type", type);

    if (type == "Order") {
      navigate(`/user/orders/details/${notification?.order?.orderNumber}`);
    }

    if (type == "User" && notification?.fromUser) {
      navigate(
        `/seller/${toSlug(notification?.fromUser?.name)}/${
          notification?.fromUser?._id
        }/profile`
      );
    }

    if (type == "ad") {
      navigate(
        `/ads/${toSlug(notification?.ad?.title)}/${notification?.ad?._id}`
      );
    }
  };

  return (
    // <NavLink to="/user/notifications" className={"c-black"}>
    <div
      onClick={() => handelNotificationType(notification.type)}
      className={` ${
        notification?.isRead ? "notification-item" : "notification-item-noRead"
      }`}
    >
      <div className="d-flex align-items-center w-75">
        <div>
          <UserAvatar url="" name="R" size="40" />
        </div>
        <div className="ms-2 w-75">
          <div className="fw-600 f-16 c-black notification-oneline">
            {notification?.title}
          </div>
          <div
            style={{ marginTop: "-4px" }}
            className="fw-400 f-14 notification-oneline"
          >
            {notification?.description}
          </div>
        </div>
      </div>

      <div className="f-12">
        {moment(notification?.createdAt).format("lll")}
      </div>
    </div>
    // </NavLink>
  );
};

export default NotificationItems;
