import React, { Fragment, useEffect, useState } from "react";

import { Table } from "react-bootstrap";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { TbDots } from "react-icons/tb";
import { IoEyeOutline, IoSearchOutline } from "react-icons/io5";
import PostRequest from "../../../services/PostRequest";
import moment from "moment";
import { formatter, showToaster } from "../../../config/common";
import { NavLink } from "react-router-dom";
import { AdsItemLoader } from "../../loader/AdsItemLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";

import Dropdown from "react-bootstrap/Dropdown";

import "./myproductitems.css";
import { LuCrown } from "react-icons/lu";
import PromoteAdModel from "../../../pages/user/ad-promotion-plans/PromoteAdModel";
import { MdOutlineDoNotDisturb } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";

const ProductItem = ({ ad, callback }) => {
  const [showPromoteModel, setShowPromoteModel] = useState(false);
  const handleClose = () => setShowPromoteModel(false);

  const handelAdsDelete = async (id) => {
    try {
      const data = {
        ad: id,
      };

      const options = {
        showLoader: ad._id,
      };

      if (!window.confirm("Are you sure you want to delete this ad?"))
        return false;

      const response = await PostRequest(`/ad/delete`, data, options);

      callback();

      showToaster("Ad Deleted Successfully.");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const handelDisableAd = async (id) => {
    try {
      const data = {
        ad: id,
      };

      const options = {
        showLoader: ad._id,
      };

      if (
        !window.confirm(
          `Are you sure you want to ${
            ad.status == "Available" ? "disable" : "Available"
          } this ad?`
        )
      )
        return false;

      const response = await PostRequest(`/ad/disable`, data, options);

      callback();

      showToaster(response);
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  return (
    <tr className="f-15">
      <td>
        <div className="d-flex align-items-center">
          <div className="rel">
            <NavLink to={`/ads/${ad.slug}/${ad._id}`} state={ad}>
              <img
                className="product-image cursor"
                src={ad.mainImage}
                alt="image"
                width="50"
              />{" "}
            </NavLink>
            {ad.isPromote && (
              <Fragment>
                <div data-tooltip-id={ad._id} className="bg-type">
                  <LuCrown size={18} />
                </div>
                <ReactTooltip
                  id={ad._id}
                  place="top"
                  content={`This is Your promoted ad, and visible on ${ad.place.join(
                    ","
                  )}`}
                  // style={{ width: "400px" }}
                />
              </Fragment>
            )}
          </div>

          <div className="ms-3 mobile-w-90 mobile-table-one-line">
            <span>{ad.title}</span>
          </div>
        </div>
      </td>
      <td className="f-15">{ad.transactionType}</td>
      <td style={{ width: "12%" }} className="c-gray f-13 mobile-table-date">
        {moment(ad.createdAt).format("lll")}
      </td>
      <td className="c-gray-dark fw-600">{formatter.format(ad.price)}</td>
      <td
        className={ad.status === "Available" ? "text-success" : "text-danger"}
      >
        {ad.status}
      </td>
      <td className="mobile-table-width-ads">
        <Dropdown>
          <Dropdown.Toggle
            className="btn-orange-outline mobile-dot-btn no-caret"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <TbDots size={20} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {!ad.isPromote && (
              <Dropdown.Item onClick={() => setShowPromoteModel(true)}>
                <div className="dropdown-bgcolor cursor">
                  <LuCrown size={18} /> <span className="ms-2">Promote</span>
                </div>
              </Dropdown.Item>
            )}

            <Dropdown.Item
              as={NavLink}
              to={`/user/post/edit/${ad._id}`}
              state={ad}
            >
              <div className="dropdown-bgcolor cursor">
                <RiEdit2Line size={18} /> <span className="ms-2">Edit</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`/ads/${ad.slug}/${ad._id}`}
              state={ad}
            >
              <div className="dropdown-bgcolor cursor">
                <IoEyeOutline size={18} />{" "}
                <span className="ms-2">View Ad Details</span>
              </div>
            </Dropdown.Item>

            <Dropdown.Item>
              <div
                onClick={() => handelDisableAd(ad._id)}
                className="dropdown-bgcolor cursor"
              >
                {ad.status == "Available" ? (
                  <MdOutlineDoNotDisturb size={18} />
                ) : (
                  <FaRegCheckCircle size={18} />
                )}

                <span className="ms-2">
                  {" "}
                  {ad.status == "Available" ? "Disable" : "Available"}
                </span>
              </div>
            </Dropdown.Item>

            <Dropdown.Item>
              <div
                onClick={() => handelAdsDelete(ad._id)}
                className="dropdown-bgcolor cursor"
              >
                <RiDeleteBinLine size={18} />{" "}
                <span className="ms-2">Delete Ad</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* PROMOTE AD MODEL */}
        {showPromoteModel && (
          <PromoteAdModel
            key={ad._id}
            handleClose={() => setShowPromoteModel(false)}
            ad={ad}
          />
        )}
      </td>
    </tr>
  );
};

var searchTime;
const MyProductItems = (props) => {
  // const [ads, setAds] = useState();

  const [loading, setLoading] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [activeFilter, setActiveFilter] = useState({});

  const [userPostItem, setUserPostItem] = useState([]);

  // console.log('userPostItem',userPostItem);/

  const loadUserAds = async (isLoadMore = false, hasExtraPayload = {}) => {
    try {
      if (!isLoadMore) {
        setLoading(true);
      }

      const data = { ...activeFilter, ...hasExtraPayload };
      const options = {
        showLoader: "load-more",
      };

      const response = await PostRequest("/user/ads", data, options);

      if (isLoadMore) {
        setUserPostItem((prev) => [...prev, ...response]);
      } else {
        setUserPostItem(response);
      }

      if (response.length < 25) {
        setHasLoadMore(false);
      } else {
        setHasLoadMore(true);
      }
    } catch (error) {
    } finally {
      if (!isLoadMore) {
        setLoading(false);
      }
    }
  };

  const handelOnChangeTransactionType = (event) => {
    setActiveFilter((pre) => ({
      ...pre,
      transactionType: event.target.value == "All" ? null : event.target.value,
    }));
  };

  const handelOnChangeStatus = (event) => {
    setActiveFilter((pre) => ({
      ...pre,
      status: event.target.value == "All" ? null : event.target.value,
    }));
  };

  const handelOnChangeSearch = (event) => {
    if (searchTime) {
      clearTimeout(searchTime);
    }
    setTimeout(() => {
      setActiveFilter((pre) => ({ ...pre, search: event.target.value }));
    }, 500);
  };

  const loadMoreMyAd = () => {
    const data = {
      skip: userPostItem.length,
    };
    loadUserAds(true, data);
  };

  useEffect(() => {
    loadUserAds();
  }, [activeFilter]);

  return (
    <div>
      <div className="row">
        <div className="col-md-5">
          <div className="input-head">
            <IoSearchOutline size={20} color="#F59449" />
            <input
              onChange={handelOnChangeSearch}
              type="text"
              className="input-search ms-2"
              placeholder="Ads tittle, keyword..."
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="offset-md-4 col-md-4 mobile-mt-2">
              <select
                onChange={handelOnChangeTransactionType}
                className="my-input c-black"
              >
                <option value="All">All</option>
                <option value="Sell">Sell</option>
                <option value="Rent">Rent</option>
              </select>
            </div>

            <div className="col-md-4 mobile-mt-2">
              <select
                onChange={handelOnChangeStatus}
                className="my-input c-black"
              >
                <option value="">All</option>
                <option value="Available">Available</option>
                <option value="Not Available">Not Available</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="gap-30"></div>

      <Table className="user-ads-list-table" hover responsive>
        <thead>
          <tr>
            <th className="th-title-50">Ads</th>
            <th>Type</th>
            <th>Date</th>
            <th>Prices</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <AdsItemLoader length={3} />
          ) : (
            <Fragment>
              {userPostItem.map((ad, index) => (
                <ProductItem callback={loadUserAds} key={index} ad={ad} />
              ))}
            </Fragment>
          )}

          {loading ? (
            ""
          ) : (
            <Fragment>
              {userPostItem.length == 0 && (
                <tr className="t-center">
                  <td colSpan={5}>
                    <h5 className="c-gray mt-3">"0" ads.</h5>
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </Table>

      {loading ? (
        ""
      ) : (
        <Fragment>
          {hasLoadMore && userPostItem.length != 0 && (
            <div className="t-center">
              <button
                id="load-more"
                onClick={loadMoreMyAd}
                className="my-button fw-600 mt-3 w-25 loadMore-btn cursor"
              >
                Load More
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MyProductItems;
