import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import MakePaymentForPromoteAd from "./MakePaymentForPromoteAd";
import { showToaster } from "../../../config/common";

const PromoteAdModel = ({ showModel, handleClose, ad }) => {
  const onPaymentComplete = () => {
    showToaster("Your ad is promoted now!");
    handleClose();
  };
  const onPaymentFailed = () => {};

  return (
    <Modal backdrop="static" size="lg" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Promoted</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MakePaymentForPromoteAd
          onPaymentComplete={onPaymentComplete}
          onPaymentFailed={onPaymentFailed}
          ad={ad}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PromoteAdModel;
