import moment from "moment";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbDots } from "react-icons/tb";
import PostRequest from "../../services/PostRequest";
import { CouponStatus, showToaster } from "../../config/common";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineDoNotDisturb } from "react-icons/md";

const CouponItem = ({ coupon, callBack }) => {
  //   const coupon = props.coupon;

  const handUpdateCoupon = async (status) => {
    try {
      var message = "";

      switch (status) {
        case "Active":
          message = "active";
          break;
        case "DeActive":
          message = "deactive";
          break;

        case "Deleted":
          message = "deleted";
          break;
      }

      if (!window.confirm(`are you sure you want to ${message} your coupon?`))
        return false;
      const data = {
        coupon: coupon._id,
        status: status,
      };

      const response = await PostRequest("/coupon/update", data);

      showToaster(`your coupon ${message} successfully.`);

      callBack();
    } catch (error) {
      showToaster(error.message, "error");
      console.log("error", error.message);
    }
  };

  return (
    <tr>
      <td className="text-one-line">
        {moment(coupon.validTo).format("DD-MM-YYYY")}
      </td>
      <td className="text-one-line">{coupon.couponCode}</td>
      <td className="text-one-line">{coupon.value}</td>
      <td className="text-one-line">{coupon.type}</td>
      <td className="text-one-line">{coupon.maxApply}</td>
      <td className={`text-one-line ${CouponStatus(coupon.status)}`}>
        {coupon.status}
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            className="btn-orange-outline mobile-dot-btn no-caret"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <TbDots size={20} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <div
                onClick={() =>
                  handUpdateCoupon(
                    coupon.status == "Active" ? "DeActive" : "Active"
                  )
                }
                className="dropdown-bgcolor cursor"
              >
                {coupon.status == "Active" ? (
                  <MdOutlineDoNotDisturb size={18} />
                ) : (
                  <FaRegCheckCircle size={18} />
                )}
                <span className="ms-2">
                  {coupon.status == "DeActive" ? "Active" : "DeActive"}
                </span>
              </div>
            </Dropdown.Item>

            <Dropdown.Item>
              <div
                onClick={() => handUpdateCoupon("Deleted")}
                className="dropdown-bgcolor cursor"
              >
                <RiDeleteBinLine size={18} />{" "}
                <span className="ms-2">Delete Coupon</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default CouponItem;
