import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getAuthUser, isMobile, toSlug } from "../../config/common";
import UserAvatar from "./UserAvatar";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import { GrAppsRounded } from "react-icons/gr";
import { LuUserCircle2 } from "react-icons/lu";
import { RiCouponLine, RiStackLine } from "react-icons/ri";
import { PiHandshakeThin, PiHeartLight, PiNotepadLight } from "react-icons/pi";
import { AiOutlineMessage } from "react-icons/ai";
import { SlSettings } from "react-icons/sl";
import { FiLogOut } from "react-icons/fi";
import { CiBank, CiCirclePlus } from "react-icons/ci";
import { FaRegCreditCard } from "react-icons/fa6";
import { MdPayments } from "react-icons/md";
import { BiSolidCoupon } from "react-icons/bi";

const UserSidebar = ({ activeUser }) => {
  const navigate = useNavigate();
  const loginUser = getAuthUser();

  const handelProfile = () => {
    navigate("/user/profile/verify");
  };

  const handleLogout = () => {
    window.localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (!loginUser) {
      navigate("/login");
    }
  }, []);

  if (!activeUser) {
    return null;
  }

  return (
    <div className="default-siadbar user-menu-list">
      <div className="d-flex align-items-center p-20">
        <div>
          <UserAvatar
            size="65"
            url={loginUser?.profileImage}
            name={loginUser?.name}
          />
        </div>
        <div className="ms-3">
          <div className="f-20 fw-600">{loginUser?.name}</div>
          {activeUser && activeUser.documentVerificationStatus && (
            <div>
              {activeUser.documentVerificationStatus == "Verified" ? (
                <div className="f-12 fw-600 c-green">
                  Verified &nbsp;
                  <FaCircleCheck size={13} />
                </div>
              ) : (
                <div onClick={handelProfile} className="f-12 fw-600 c-orange">
                  Verification Pending &nbsp;
                  <IoCloseCircleSharp size={14} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <hr />

      <div>
        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/dashboard"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <GrAppsRounded size={20} />
            </div>
            <div className="w-100">Overview</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to={`/seller/${toSlug(activeUser.name)}/${activeUser._id}/profile`}
            state={activeUser}
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <LuUserCircle2 size={23} />
            </div>
            <div className="w-100">View Public Profile</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/orders"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <RiStackLine size={23} />
            </div>
            <div className="w-100">My Orders</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/sell-rent/orders"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <PiHandshakeThin size={23} />
            </div>
            <div className="w-100">Received Orders</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/post/create"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <CiCirclePlus size={24} />
            </div>
            <div className="w-100">Post an Ad</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/ads"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <PiNotepadLight size={24} />
            </div>
            <div className="w-100">My Ads</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/coupon"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <RiCouponLine size={23} />
            </div>
            <div className="w-100">Coupons</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/favorite"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <PiHeartLight size={23} />
            </div>
            <div className="w-100">Favorites</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/messages"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <AiOutlineMessage size={20} />
            </div>
            <div className="w-100">Messages</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/payment/details"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <FaRegCreditCard size={20} />
            </div>
            <div className="w-100">Payments</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/payment/settings"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <CiBank size={23} />
            </div>
            <div className="w-100">Payment Settings</div>
          </NavLink>
        </div>

        <div className="dashboard-bg-menu">
          <NavLink
            to="/user/profile/settings"
            className={`c-gray ${({ isActive }) => (isActive ? "active" : "")}`}
          >
            <div className="w-15">
              <SlSettings size={20} />
            </div>
            <div className="w-100">Account Settings</div>
          </NavLink>
        </div>

        <div onClick={handleLogout} className="dashboard-bg-menu cursor">
          <span className={`c-gray d-flex ms-4 mt-2 fw-600 no-active`}>
            <div className="w-15">
              <FiLogOut size={20} />
            </div>
            <div className="w-100">Logout</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
