import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";

const ChooseLocationFromMap = ({
  placeholder = "Choose Location",
  selectCoords = false,
  initialLocation = false,
  onChangeAddress = () => {},
  // defaultValue = {},
}) => {
  var latitude = initialLocation?.latitude || 43.65107;
  var longitude = initialLocation?.longitude || -79.347015;

  const [marker, setMarker] = useState(false);
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [address, setAddress] = useState(false);

  // Handles location change on map click
  const handleChangeLocation = (obj) => {
    // console.log("obj", obj);

    setMarker({
      lat: obj.lat,
      lng: obj.lng,
    });
    getAddress(obj.lat, obj.lng);
  };

  // Fetches address components from Geocoding API
  const getAddress = (lat, lng) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB44pxd0_owXRfMD3I7Ep_F4YRqrd3rXQo`;

    // const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?latlng=${lat},${lng}&key=AIzaSyB44pxd0_owXRfMD3I7Ep_F4YRqrd3rXQo`;
    axios
      .get(geocodeUrl)
      .then((response) => {
        // console.log("response", response);

        if (response.data.results.length > 0) {
          const result = response.data.results[0];
          const addressComponents = result.address_components.reduce(
            (acc, component) => {
              if (component.types.includes("locality")) {
                acc.city = component.long_name;
              } else if (
                component.types.includes("administrative_area_level_1")
              ) {
                acc.state = component.long_name;
              } else if (component.types.includes("country")) {
                acc.country = component.long_name;
              } else if (component.types.includes("postal_code")) {
                acc.pinCode = component.long_name;
              } else {
                acc.address += component.long_name + " ";
              }
              return acc;
            },
            {
              address: "",
              city: "",
              state: "",
              country: "",
              pinCode: "",
            }
          );

          setAddress({
            ...addressComponents,
            address: addressComponents.address.trim(),
          });
        } else {
          setAddress({
            address: "",
            city: "",
            state: "",
            country: "",
            pinCode: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching address: ", error);
        setAddress({
          address: "Error fetching address",
          city: "",
          state: "",
          country: "",
          pinCode: "",
        });
      });
  };

  // Marker component
  const AnyReactComponent = () => (
    <img
      style={{
        width: "50px",
        height: "50px",
        position: "relative",
        top: "-50px",
        left: "-25px",
      }}
      height={50}
      width={50}
      src="/images/pin.png"
      alt="Marker"
    />
  );

  // Update state when selectCoords changes
  useEffect(() => {
    if (selectCoords) {
      setMarker(selectCoords);
      setCenter(selectCoords);
      getAddress(selectCoords.lat, selectCoords.lng);
    }
  }, [selectCoords]);

  useEffect(() => {
    if (address) {
      const cloneAddress = { ...address };
      cloneAddress.lat = marker.lat;
      cloneAddress.lng = marker.lng;

      // console.log('cloneAddress',cloneAddress)

      onChangeAddress(cloneAddress);

      // console.log("cloneAddress", cloneAddress);
      // onChangeAddress(defaultValue);
    }
  }, [address, marker]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (initialLocation) {
      setCenter({
        lat: initialLocation?.latitude,
        lng: initialLocation?.longitude,
      });

      setMarker({
        lat: initialLocation?.latitude,
        lng: initialLocation?.longitude,
      });
    }
  }, [initialLocation]);

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <div className="fw-600 f-16 mt-2 mb-1">{placeholder}</div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB44pxd0_owXRfMD3I7Ep_F4YRqrd3rXQo",
        }}
        center={center}
        defaultZoom={10}
        onClick={(obj) => handleChangeLocation(obj)}
      >
        {marker && <AnyReactComponent lat={marker.lat} lng={marker.lng} />}
      </GoogleMapReact>
    </div>
  );
};

export default ChooseLocationFromMap;
